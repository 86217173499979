<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="mod">
            <div class="modal__inner choose-wallet">
                <div class="modal__up">
                    <p class="modal__name">Switch the network</p>
                    <i @click="close()" class="i-close-line"></i>
                </div>
                <div class="modal__down switch">
                    <p>
                        To switch the network in the ONTO wallet, disconnect from the current one, then connect to the intended one.<br />
                        Here are the detailed instructions on how to do it.
                    </p>
                    <a href="https://publicdocs.gitbook.io/onto-web-help-center/guides/other-features/connect-to-websites" target="_blank"
                        ><button class="instruction">instruction</button></a
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OntoWallet",
        methods: {
            close() {
                this.$store.commit("setShowOntoTutorial", false);
            },
        },
    };
</script>

<style scoped></style>
