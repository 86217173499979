<template>
    <div class="mobile__menu">
        <div class="mobile__inner">
            <!-- <div class="mobile__nav" v-if="$route.name === 'Home'">
                <ul class="mobile__link">
                    <li @click="$emit('close')">
                        <a href="#screen_1" class="hover">{{ lang.get("BRING_FEATURES") }}</a>
                    </li>
                    <li @click="$emit('close')">
                        <a href="#screen_2" class="hover">{{ lang.get("BRING_POOLS") }}</a>
                    </li>
                    <li @click="$emit('close')">
                        <a href="#screen_2" class="hover">{{ lang.get("FAQ") }}</a>
                    </li>
                    <li @click="$emit('close')">
                        <a href="#screen_2" class="hover">{{ lang.get("ROADMAP") }}</a>
                    </li>
                    <li @click="$emit('close')">
                        <router-link class="hover" :to="{ name: 'pool' }" active-class="active"> {{ lang.get("POOL") }} </router-link>
                    </li>
                </ul>
            </div> -->
            <div class="mobile__nav">
                <ul class="mobile__link">
                    <li v-if="$route.name !== 'Home'" @click="onClick('Main')">
                        <router-link @click="$emit('close')" class="hover" :to="{ name: 'Main' }"> Main </router-link>
                    </li>
                    <li @click="onClick('pool')">
                        <router-link class="hover" :to="{ name: 'pool' }"> {{ lang.get("POOL") }} </router-link>
                    </li>
                    <li @click="onClick('stake')">
                        <router-link class="hover" :to="{ name: 'stake' }">Your Stakes</router-link>
                    </li>
                    <li @click="onClick('referral')">
                        <router-link class="hover" :to="{ name: 'referral' }">REFERRALS</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div class="mobile__buttons">
            <div class="mobile__first__btn">
                <button
                    v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'"
                    @click="$emit('changeWalletRequest')"
                    class="btn__acc__new"
                >
                    {{ addressShort }}
                </button>
                <button v-else @click="$emit('connectWallet')" class="btn__connect">
                    {{ lang.get("CONNECT_WALLET") }}
                </button>

                <div
                    v-if="currentAddress !== '0x0000000000000000000000000000000000000000'"
                    class="dropdown__new drop__item"
                    @click="showMobileAllWallets = !showMobileAllWallets"
                    :class="{ active: showMobileAllWallets }"
                >
                    <div class="dropdown__inner" v-if="isSupportedChainId[0]">
                        <span
                            class="wallet_icon"
                            :class="{
                                'network-binance': userChainId === 56,
                                'network-ethereum': userChainId > 0 && userChainId <= 5,
                                'network-harmony': userChainId === 1666600000,
                            }"
                        ></span>
                        <span class="select__new">{{ isSupportedChainId[1] }}</span>
                    </div>
                    <div v-else class="second__flex">
                        <span
                            class="wallet_icon"
                            :class="{
                                'network-binance': userChainId === 56,
                                'network-ethereum': userChainId > 0 && userChainId <= 5,
                                'network-harmony': userChainId === 1666600000,
                            }"
                        ></span>
                        <span class="select__new">{{ isSupportedChainId[1] }}</span>
                    </div>
                    <i class="i-arrow-drop-down-line"></i>
                    <div class="dropdown-list__new">
                        <div class="text__new__drop">
                            <p class="new__inner">Switch network</p>
                        </div>
                        <div @click="changeNetwork('eth')" class="dropdown-list__item__new">
                            <div class="coin">
                                <div
                                    class="coin__inner"
                                    :class="{
                                        active__now: userChainId > 0 && userChainId <= 5,
                                    }"
                                >
                                    <div class="coin__block">
                                        <span
                                            class="wallet_icon network-ethereum"
                                            :class="{
                                                // 'network-binance': userChainId === 97,
                                                // 'network-ethereum': userChainId <=5,
                                                // 'network-harmony': userChainId === 1666700000,
                                            }"
                                        ></span>
                                        <div
                                            class="wallet__pools"
                                            :class="{
                                                active__now: userChainId > 0 && userChainId <= 5,
                                            }"
                                        >
                                            Ethereum
                                        </div>
                                    </div>
                                    <div class="wallet__done">
                                        <i class="i-checkbox-circle-fill"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div @click="changeNetwork('bsc')" class="dropdown-list__item__new up__new">
                            <div class="coin">
                                <div class="coin__inner" :class="{ active__now: userChainId === 56 }">
                                    <div class="coin__block">
                                        <span
                                            class="wallet_icon network-binance"
                                            :class="{
                                                // 'network-binance': userChainId === 97,
                                                // 'network-ethereum': userChainId <=5,
                                                // 'network-harmony': userChainId === 1666700000,
                                            }"
                                        ></span>
                                        <div class="wallet__pools" :class="{ active__now: userChainId === 56 }">BNB Chain</div>
                                    </div>
                                    <div class="wallet__done">
                                        <i class="i-checkbox-circle-fill"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div @click="changeNetwork('harm')" class="dropdown-list__item__new up__new">
                            <div class="coin">
                                <div class="coin__inner" :class="{ active__now: userChainId === 1666600000 }">
                                    <div class="coin__block">
                                        <span
                                            class="wallet_icon network-harmony"
                                            :class="{
                                                // 'network-binance': userChainId === 97,
                                                // 'network-ethereum': userChainId <=5,
                                                // 'network-harmony': userChainId === 1666700000,
                                            }"
                                        ></span>
                                        <div class="wallet__pools" :class="{ active__now: userChainId === 1666600000 }">Harmony</div>
                                    </div>
                                    <div class="wallet__done">
                                        <i class="i-checkbox-circle-fill"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text__new__drop">
                            <p class="new__inner">Previous versions</p>
                        </div>
                        <div class="dropdown-list__item__new">
                            <a href="https://eth.bring.finance/" target="_blank">
                                <div class="coin">
                                    <div class="coin__inner">
                                        <div class="coin__block">
                                            <span
                                                class="wallet_icon network-ethereum"
                                                :class="{
                                                    // 'network-binance': userChainId === 97,
                                                    // 'network-ethereum': userChainId <=5,
                                                    // 'network-harmony': userChainId === 1666700000,
                                                }"
                                            ></span>
                                            <div class="wallet__pools">ETH Pools V1</div>
                                        </div>
                                        <div class="wallet__done">
                                            <i class="i-checkbox-circle-fill"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="dropdown-list__item__new up__new">
                            <a href="https://bsc.bring.finance/" target="_blank">
                                <div class="coin">
                                    <div class="coin__inner">
                                        <div class="coin__block">
                                            <span
                                                class="wallet_icon network-binance"
                                                :class="{
                                                    // 'network-binance': userChainId === 97,
                                                    // 'network-ethereum': userChainId <=5,
                                                    // 'network-harmony': userChainId === 1666700000,
                                                }"
                                            ></span>
                                            <div class="wallet__pools">BNB Pools V1</div>
                                        </div>
                                        <div class="wallet__done">
                                            <i class="i-checkbox-circle-fill"></i>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile__second-wrapper">
                <div class="mobile__second__btn">
                    <a
                        href="https://pancakeswap.finance/swap?inputCurrency=0x939d5a13cf0074586a2dcf17bc692b2d3ccdd517"
                        target="_blank"
                        rel="nofollow"
                        class="btn__buy"
                    >
                        <i class="i-price-tag-2-fill"> </i>
                        {{ lang.get("BUY_BRNG") }}
                    </a>
                </div>
                <div class="mobile__second__btn bridge-brt">
                    <a href="https://bridge.bring.finance/" target="_blank" class="btn__bridge-icon"> bring.Bridge </a>
                    <div class="icon__bridge"></div>
                </div>
            </div>

            <div class="mobile-social__link">
                <div class="links-main links-main_mobile">
                    <div class="container-link" data-cy="list-links">
                        <div>
                            <a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"><span class="twitter"></span></a>
                        </div>
                        <div>
                            <a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"><span class="medium"></span></a>
                        </div>
                        <div>
                            <a href="https://www.youtube.com/channel/UCyG5ZG13kiXvvSeRxdIG_jg" rel="nofollow" target="_blank"><span class="youtube"></span></a>
                        </div>
                        <div>
                            <a href="https://t.me/bRing_community" target="_blank" rel="nofollow"><span class="telegram"></span></a>
                            <div class="telegram-group">
                                <i class="i-team-line"></i>
                            </div>
                        </div>
                        <div>
                            <a href="https://t.me/bRing_channel" rel="nofollow" target="_blank"
                                ><span class="telegram speaker"> <i class="i-speaker"></i></span
                            ></a>
                            <div class="telegram-group">
                                <i class="i-volume-up-line"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import MultiLang from "@/core/multilang";
    import LangMenu from "./LangMenu.vue";
    import MobileMenu from "./MobileMenu.vue";
    import conf from "../../Config.json";
    export default {
        components: {
            LangMenu,
            MobileMenu,
        },
        data: function () {
            return {
                lang: new MultiLang(this),
                showMobileMenu: false,
                showMobileAllWallets: false,
            };
        },
        computed: {
            ...mapState(["currentAddress", "currentBlockchain"]),
            addressShort() {
                if (this.currentAddress) {
                    return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length);
                }
            },
            userChainId() {
                const selectedWallet = window.localStorage.getItem("selectedWallet");

                if (selectedWallet && selectedWallet === "metamask") {
                    if (window.ethereum && window.ethereum.chainId) {
                        return Number(window.ethereum.chainId);
                    }
                } else if (selectedWallet && selectedWallet === "walletconnect") {
                    const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                    if (WC_Obj.chainId) {
                        return Number(WC_Obj.chainId);
                    }
                }
            },
            isSupportedChainId() {
                const selectedWallet = window.localStorage.getItem("selectedWallet");
                if (selectedWallet === "metamask" && window.ethereum) {
                    if (Number(window.ethereum.chainId) > 0) {
                        if (conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(window.ethereum.chainId)) >= 0) {
                            return [true, conf.BLOCKCHAINS[Number(window.ethereum.chainId)], Number(window.ethereum.chainId)];
                        } else {
                            return [false, conf.BLOCKCHAINS[Number(window.ethereum.chainId)] || "Undefined network", Number(window.ethereum.chainId)];
                        }
                    } else {
                        // console.log("no chain id detected");
                        setTimeout(this.isSupportedChainId, 300);
                        return [];
                    }
                } else if (selectedWallet === "walletconnect") {
                    const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                    if (Number(WC_Obj.chainId) > 0) {
                        if (conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(WC_Obj.chainId)) >= 0) {
                            return [true, conf.BLOCKCHAINS[Number(WC_Obj.chainId)], Number(WC_Obj.chainId)];
                        } else {
                            return [false, conf.BLOCKCHAINS[Number(WC_Obj.chainId)] || "Undefined network", Number(WC_Obj.chainId)];
                        }
                    } else {
                        // console.log("no chain id detected");
                        setTimeout(this.isSupportedChainId, 300);
                        return [];
                    }
                }
                return [false, conf.PRIMARY_BLOCKCHAIN.name, conf.PRIMARY_BLOCKCHAIN.chainId];
            },
        },
        methods: {
            onClick(name) {
                this.$router.push({ name: name });
                this.$emit("close");
            },
            async changeNetwork(blockchain) {
                await this.$root.core.changeNetwork(this, blockchain);
            },
        },
        mounted() {
            this.lang.init();
        },
    };
</script>
