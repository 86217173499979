<template>
    <div class="header__wrapper">
        <header>
            <div class="header">
                <a v-if="$route.name === 'Main'" class="main_logo" href="#">
                    <div class="logo">
                        <span class="logo__span"> </span>
                    </div>
                </a>
                <router-link v-else class="main_logo" tag="a" :to="{ name: 'Main' }">
                    <div class="logo">
                        <span class="logo__span"> </span>
                    </div>
                </router-link>
                <!-- <div class="nav" v-if="$route.name === 'Home'">
                    <ul class="navigation">
                        <li>
                            <a href="#screen_1" class="hover">{{ lang.get("BRING_FEATURES") }}</a>
                        </li>
                        <li>
                            <a href="#screen_2" class="hover">{{ lang.get("BRING_POOLS") }}</a>
                        </li>
                        <li>
                            <a href="#screen_2" class="hover">{{ lang.get("FAQ") }}</a>
                        </li>
                        <li>
                            <a href="#screen_2" class="hover">{{ lang.get("ROADMAP") }}</a>
                        </li>
                        <li>
                            <router-link class="hover" :to="{ name: 'pool' }" active-class="active"> {{ lang.get("POOL") }} </router-link>
                        </li>
                    </ul>
                </div> -->
                <div class="nav">
                    <ul class="navigation">
                        <!--                        <li>-->
                        <!--                            <router-link class="hover" :to="{ name: 'Main' }" :class="{ active: $route.name === 'Main' }">-->
                        <!--                                {{ lang.get("HOME") }}-->
                        <!--                            </router-link>-->
                        <!--                        </li>-->
                        <li>
                            <router-link class="hover" :to="{ name: 'pool' }" active-class="active"> {{ lang.get("POOL") }} </router-link>
                        </li>
                        <li>
                            <router-link class="hover" :to="{ name: 'stake' }" active-class="active"> {{ lang.get("YOUR_STAKE") }} </router-link>
                        </li>
                        <li>
                            <router-link class="hover" :to="{ name: 'referral' }" active-class="active"> {{ lang.get("REFERRAL") }} </router-link>
                        </li>
                    </ul>
                </div>

                <div class="new__btn__fheader">
                    <div class="buttons">
                        <ul class="btn">
                            <li>
                                <a href="https://bridge.bring.finance/" target="_blank" class="btn__bridge">
                                    <div class="icon__bridge"></div>
                                    bring.Bridge
                                </a>
                            </li>
                            <li class="btn__bridge-mob">
                                <a href="https://bridge.bring.finance/" target="_blank" class="btn__bridge-icon">
                                    <div class="icon__bridge"></div>
                                </a>
                            </li>
                            <li class="btn__bring-mob">
                                <a
                                    href="https://pancakeswap.finance/swap?inputCurrency=0x939d5a13cf0074586a2dcf17bc692b2d3ccdd517"
                                    target="_blank"
                                    class="btn__bring-icon"
                                >
                                    <i class="i-price-tag-2-fill"> </i>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://pancakeswap.finance/swap?inputCurrency=0x939d5a13cf0074586a2dcf17bc692b2d3ccdd517"
                                    target="_blank"
                                    rel="nofollow"
                                    class="btn__buy"
                                >
                                    <i class="i-price-tag-2-fill"> </i>
                                    {{ lang.get("BUY_BRNG") }}
                                </a>
                            </li>

                            <li v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'">
                                <button @click="$emit('changeWalletRequest'), (showMobileMenu = false)" class="btn__acc__new">{{ addressShort }}</button>
                            </li>
                            <li v-else>
                                <button @click="$emit('connectWallet'), (showMobileMenu = false)" class="btn__connect header-connect">
                                    {{ lang.get("CONNECT_WALLET") }}
                                </button>
                            </li>
                        </ul>
                    </div>

                    <!-- <div v-if="!currentAddress || currentAddress === '0x0000000000000000000000000000000000000000'" class="dropdown__new add">
                        <span
                            class="wallet_icon"
                            :class="{
                                'network-binance':
                                    isSupportedChainId().length > 0 && (Number(isSupportedChainId()[2]) === 56 || Number(isSupportedChainId()[2]) === 56),
                                'network-ethereum':
                                    isSupportedChainId().length > 0 && Number(isSupportedChainId()[2]) > 0 && Number(isSupportedChainId()[2]) <= 5,
                                'network-harmony': isSupportedChainId().length > 0 && Number(isSupportedChainId()[2]) === 1666600000,
                            }"
                        ></span>
                        <span class="select__new">{{ isSupportedChainId()[1] }}</span>
                        <i> </i>
                        <br />
                    </div> -->
                    <div
                        v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'"
                        class="dropdown__new"
                        @click="showAllWallets = !showAllWallets"
                        :class="{ active: showAllWallets }"
                    >
                        <div class="dropdown__inner" v-if="isSupportedChainId()[0]">
                            <span
                                class="wallet_icon"
                                :class="{
                                    'network-binance': (isSupportedChainId().length > 0 && isSupportedChainId()[2] === 56) || isSupportedChainId()[2] === 56,
                                    'network-ethereum': isSupportedChainId().length > 0 && Number(isSupportedChainId()[2]) > 0 && isSupportedChainId()[2] <= 5,
                                    'network-harmony': isSupportedChainId().length > 0 && isSupportedChainId()[2] === 1666600000,
                                }"
                            ></span>
                            <span class="select__new">{{ isSupportedChainId()[1] }}</span>
                        </div>
                        <div v-else class="second__flex">
                            <span
                                class="wallet_icon"
                                :class="{
                                    'network-binance':
                                        isSupportedChainId().length > 0 && (Number(isSupportedChainId()[2]) === 56 || Number(isSupportedChainId()[2]) === 56),
                                    'network-ethereum':
                                        isSupportedChainId().length > 0 && Number(isSupportedChainId()[2]) > 0 && Number(isSupportedChainId()[2]) <= 5,
                                    'network-harmony': isSupportedChainId().length > 0 && Number(isSupportedChainId()[2]) === 1666600000,
                                }"
                            ></span>
                            <span class="select__new">{{ isSupportedChainId()[1] }}</span>
                        </div>
                        <i class="i-arrow-drop-down-line"></i>
                        <div class="dropdown-list__new">
                            <div class="text__new__drop">
                                <p class="new__inner">Switch network</p>
                            </div>
                            <div @click="changeNetwork('eth')" class="dropdown-list__item__new">
                                <div class="coin">
                                    <div
                                        class="coin__inner"
                                        :class="{
                                            active__now: isSupportedChainId().length > 0 && isSupportedChainId()[2] > 0 && isSupportedChainId()[2] <= 5,
                                        }"
                                    >
                                        <div class="coin__block">
                                            <span
                                                class="wallet_icon network-ethereum"
                                                :class="{
                                                    // 'network-binance': currentBlockchain === 56,
                                                    // 'network-ethereum': currentBlockchain <=5,
                                                    // 'network-harmony': currentBlockchain === 1666600000,
                                                }"
                                            ></span>
                                            <div
                                                class="wallet__pools"
                                                :class="{
                                                    active__now: isSupportedChainId().length > 0 && isSupportedChainId()[2] > 0 && isSupportedChainId()[2] <= 5,
                                                }"
                                            >
                                                Ethereum
                                            </div>
                                        </div>
                                        <div class="wallet__done">
                                            <i
                                                v-if="isSupportedChainId().length > 0 && isSupportedChainId()[2] > 0 && isSupportedChainId()[2] <= 5"
                                                class="i-checkbox-circle-fill"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div @click="changeNetwork('bsc')" class="dropdown-list__item__new up__new">
                                <div class="coin">
                                    <div class="coin__inner" :class="{ active__now: isSupportedChainId().length > 0 && isSupportedChainId()[2] === 56 }">
                                        <div class="coin__block">
                                            <span
                                                class="wallet_icon network-binance"
                                                :class="{
                                                    // 'network-binance': currentBlockchain === 56,
                                                    // 'network-ethereum': currentBlockchain <=5,
                                                    // 'network-harmony': currentBlockchain === 1666600000,
                                                }"
                                            ></span>
                                            <div
                                                class="wallet__pools"
                                                :class="{ active__now: isSupportedChainId().length > 0 && isSupportedChainId()[2] === 56 }"
                                            >
                                                BNB Chain
                                            </div>
                                        </div>
                                        <div class="wallet__done">
                                            <i v-if="isSupportedChainId().length > 0 && isSupportedChainId()[2] === 56" class="i-checkbox-circle-fill"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div @click="changeNetwork('harm')" class="dropdown-list__item__new up__new">
                                <div class="coin">
                                    <div class="coin__inner" :class="{ active__now: currentBlockchain === 1666600000 && isSupportedChainId()[0] === true }">
                                        <div class="coin__block">
                                            <span
                                                class="wallet_icon network-harmony"
                                                :class="{
                                                    // 'network-binance': currentBlockchain === 56,
                                                    // 'network-ethereum': currentBlockchain <=5,
                                                    // 'network-harmony': currentBlockchain === 1666600000,
                                                }"
                                            ></span>
                                            <div
                                                class="wallet__pools"
                                                :class="{ active__now: isSupportedChainId().length > 0 && isSupportedChainId()[2] === 1666600000 }"
                                            >
                                                Harmony
                                            </div>
                                        </div>
                                        <div class="wallet__done">
                                            <i class="i-checkbox-circle-fill"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="text__new__drop">
                                <p class="new__inner">Previous versions</p>
                            </div>
                            <div class="dropdown-list__item__new">
                                <a href="https://eth.bring.finance/cabinet/pool" target="_blank">
                                    <div class="coin">
                                        <div class="coin__inner">
                                            <div class="coin__block">
                                                <span
                                                    class="wallet_icon network-ethereum"
                                                    :class="{
                                                        // 'network-binance': currentBlockchain === 56,
                                                        // 'network-ethereum': currentBlockchain <=5,
                                                        // 'network-harmony': currentBlockchain === 1666600000,
                                                    }"
                                                ></span>
                                                <div class="wallet__pools">ETH Pools V1</div>
                                            </div>
                                            <!-- <div class="wallet__done">
                                                <i v-if="currentBlockchain > 0 && currentBlockchain <= 5" class="i-checkbox-circle-fill"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="dropdown-list__item__new up__new">
                                <a href="https://bsc.bring.finance/" target="_blank">
                                    <div class="coin">
                                        <div class="coin__inner">
                                            <div class="coin__block">
                                                <span
                                                    class="wallet_icon network-binance"
                                                    :class="{
                                                        // 'network-binance': currentBlockchain === 56,
                                                        // 'network-ethereum': currentBlockchain <=5,
                                                        // 'network-harmony': currentBlockchain === 1666600000,
                                                    }"
                                                ></span>
                                                <div class="wallet__pools">BNB Pools V1</div>
                                            </div>
                                            <!-- <div class="wallet__done">
                                                <i v-if="currentBlockchain === 56" class="i-checkbox-circle-fill"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="adaptive__btn">
                        <button @click="showMobileMenu = !showMobileMenu" v-if="showMobileMenu"><i class="i-close-line"></i></button>
                        <button @click="showMobileMenu = !showMobileMenu" v-else><i class="i-menu-3-fill"></i></button>
                    </div>
                </div>
            </div>
        </header>
        <mobile-menu
            v-if="showMobileMenu"
            @close="showMobileMenu = false"
            @changeWalletRequest="$emit('changeWalletRequest'), (showMobileMenu = false)"
            @connectWallet="$emit('connectWallet'), (showMobileMenu = false)"
        />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import MultiLang from "@/core/multilang";
    import LangMenu from "./LangMenu.vue";
    import MobileMenu from "./MobileMenu.vue";
    import conf from "../../Config.json";
    export default {
        components: {
            LangMenu,
            MobileMenu,
        },
        data: function () {
            return {
                lang: new MultiLang(this),
                showMobileMenu: false,
                showAllWallets: false,
            };
        },
        computed: {
            ...mapState(["currentAddress", "currentBlockchain"]),
            addressShort() {
                if (this.currentAddress) {
                    return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length);
                }
            },
            userChainId() {
                const selectedWallet = window.localStorage.getItem("selectedWallet");

                if (selectedWallet && selectedWallet === "metamask") {
                    if (window.ethereum && window.ethereum.chainId) {
                        return Number(window.ethereum.chainId);
                    }
                } else if (selectedWallet && selectedWallet === "walletconnect") {
                    const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                    if (WC_Obj.chainId) {
                        return Number(WC_Obj?.chainId);
                    }
                }
            },
        },
        methods: {
            isSupportedChainId() {
                const selectedWallet = window.localStorage.getItem("selectedWallet");
                if (selectedWallet === "metamask" && window.ethereum) {
                    if (Number(window.ethereum.chainId) > 0) {
                        if (conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(window.ethereum.chainId)) >= 0) {
                            return [true, conf.BLOCKCHAINS[Number(window.ethereum.chainId)], Number(window.ethereum.chainId)];
                        } else {
                            return [false, conf.BLOCKCHAINS[Number(window.ethereum.chainId)] || "Undefined network", Number(window.ethereum.chainId)];
                        }
                    } else {
                        // console.log("no chain id detected");
                        setTimeout(this.isSupportedChainId, 300);
                        return [];
                    }
                } else if (selectedWallet === "onto" && window.onto) {
                    if (Number(window.onto.chainId) > 0) {
                        if (conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(window.onto.chainId)) >= 0) {
                            return [true, conf.BLOCKCHAINS[Number(window.onto.chainId)], Number(window.onto.chainId)];
                        } else {
                            return [false, conf.BLOCKCHAINS[Number(window.onto.chainId)] || "Undefined network", Number(window.onto.chainId)];
                        }
                    } else {
                        // console.log("no chain id detected");
                        setTimeout(this.isSupportedChainId, 300);
                        return [];
                    }
                } else if (selectedWallet === "walletconnect") {
                    const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                    if (Number(WC_Obj?.chainId) > 0) {
                        if (conf.SUPPORTED_BLOCKCHAINS.indexOf(Number(WC_Obj.chainId)) >= 0) {
                            return [true, conf.BLOCKCHAINS[Number(WC_Obj?.chainId)], Number(WC_Obj?.chainId)];
                        } else {
                            return [false, conf.BLOCKCHAINS[Number(WC_Obj?.chainId)] || "Undefined network", Number(WC_Obj?.chainId)];
                        }
                    } else {
                        // console.log("no chain id detected");
                        setTimeout(this.isSupportedChainId, 300);
                        return [];
                    }
                }
                return [false, conf.PRIMARY_BLOCKCHAIN.name, conf.PRIMARY_BLOCKCHAIN.chainId];
            },
            async changeNetwork(blockchain) {
                await this.$root.core.changeNetwork(this, blockchain);
            },
            moveToTop() {
                window.moveTo(0, 0);
            },
        },
        mounted() {
            this.lang.init();
        },
    };
</script>
