<template>
  <button class="lang">
    
    <i class="i-map" data-cv="list-lang"></i>
    <span class="selected-lang">{{selectedLang}}</span>

    <ul class="container-lang">
        <li>
          <button @click="setLang('en', $event)">
            <div class="icon_lang icon_lang-en"></div>
            <span>En</span>
          </button>
        </li>
        <!-- <li>
          <button @click="setLang('ru', $event)">
            <div class="icon_lang icon_lang-ru"></div>
            <span>Ru</span>
          </button>
        </li> -->
    </ul>
  </button> 
</template>

<script>
import MultiLang from "@/core/multilang";
export default {
  data: function () {
    return {
      lang: new MultiLang(this),
      selectedLang: ""
    };
  },

  mounted() {
    this.selectedLang = localStorage.getItem('lang')
  },
  computed: {
  
  },
  methods: {
    setLang(lang, event) {
      this.selectedLang = lang;
      this.$store.dispatch("updateLanguage", lang);
      // this.$root.core.setLangForAddress(lang);

    },
    setActiveLang(inputLang) {
      return inputLang === this.selectedLang;
    },
    setFlagImg() {
      let lang = localStorage.getItem('lang');
      return lang ? `${lang}Icon` : "enIcon";
    },
    getLang() {
      return localStorage.getItem('lang');
    }
  },
  computed: {
    
  }
};
</script>
