import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTippy, { TippyComponent } from "vue-tippy";
import "../src/assets/style/style.css";
import "../src/assets/style/media.css";
import "../src/assets/style/variables.css";
import "../src/assets/style/animation.css";
import Clipboard from "v-clipboard";

import axios from "axios";

Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                // Вернуть 401, очистить информацию о токене и перейти на страницу входа
                case 500:
                    router.replace({
                        path: "/server-error",
                        // Перейти на текущую страницу после успешного входа
                        // query: {redirect: router.currentRoute.fullPath}
                    });
            }
            // Возвращаем сообщение об ошибке, возвращаемое интерфейсом
            return Promise.reject(error.response.data);
        }
    }
);
Vue.use(VueTippy, {
    directive: "tippy", // => v-tippy
    flipDuration: 0,
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false,
            },
        },
    },
});

Vue.component("tippy", TippyComponent);
Vue.use(Clipboard);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
