import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        currentAddress: "0x0000000000000000000000000000000000000000",
        stakeTokens: [],
        lang: "en",
        stakesInfo: null,
        stakesInfoFromContract: null,
        lastStakesFromContract: null,
        farmTokens: [],
        totalTokensNumber: 0,
        refNumber: null,
        totalStake: 0,
        tokensTotalStake: {},
        blockchainLastBlock: 0,
        currentBlockchain: "",
        rewardPageData: {},
        penaltyInfo: {},
        currentRate: {},
        hasReferrerObj: {},
        userCoinBalance: 0,
        allContractsData: {},
        showOntoTutorial: false,
    },
    mutations: {
        setShowOntoTutorial(state, data) {
            state.showOntoTutorial = data;
        },
        setLastStakesFromContract(state, data) {
            state.lastStakesFromContract = [data[data.length - 1]];
            if (state.stakesInfo !== null && state.stakesInfo.length > 0) {
                const index = state.stakesInfo.findIndex(
                    (el) => el.contractId === data[data.length - 1].contractId && Number(el.stakeIdx) === Number(data[data.length - 1].stakeId)
                );

                if (index < 0) {
                    state.stakesInfo = [data[data.length - 1], ...state.stakesInfo];
                }
            } else {
                state.stakesInfo = data;
            }
        },

        setPoolPenaltyInfo(state, data) {
            state.penaltyInfo = data;
        },
        setAllContractsData(state, data) {
            state.allContractsData = data;
        },
        setRewardPageData(state, data) {
            state.rewardPageData = data;
        },

        setUserCoinBalance(state, data) {
            state.userCoinBalance = data;
        },
        setReferrerObj(state, data) {
            state.hasReferrerObj = data;
        },
        setRate(state, data) {
            state.currentRate = data;
        },
        setTotalStake(state, data) {
            state.totalStake = data;
        },
        setLastBlock(state, data) {
            state.blockchainLastBlock = data;
        },
        setTokensTotalStake(state, data) {
            state.tokensTotalStake = data;
        },
        setStakeTokens(state, data) {
            state.stakeTokens = data;
        },
        setReferralsNumber(state, data) {
            state.refNumber = data;
        },
        setLanguage(state, lang) {
            state.lang = lang;
        },
        setCurrentAddress(state, address) {
            state.currentAddress = address;
        },
        setStakesInfo(state, data) {
            try {
                if (!state.stakesInfo) {
                    state.stakesInfo = data;
                    // if (!data[0].farmTokensReward || data[0]?.farmTokensReward?.length === 0) {
                    // for debugging
                    // }
                } else if (state.stakesInfo && state.lastStakesFromContract?.length && data.length === state.stakesInfo.length) {
                    state.stakesInfo = data;
                    state.lastStakesFromContract = null;
                } else if (state.stakesInfo && !state.lastStakesFromContract && data.length === state.stakesInfo.length) {
                    state.stakesInfo = data;
                    // if (!data[0]?.farmTokensReward || data[0]?.farmTokensReward?.length === 0) {
                    // for debugging
                    // }
                } else if (state.stakesInfo && !state.lastStakesFromContract && state.stakesInfo.length === data.length + 1) {
                    state.stakesInfo = data;
                }
            } catch (error) {
                console.log("error saving stakes to state: ", error);
            }
        },
        setStakesInfoFromContract(state, data) {
            state.stakesInfoFromContract = data;
        },
        setTotalTokensNumber(state, data) {
            state.totalTokensNumber = data;
        },
        setFarmTokens(state, data) {
            state.farmTokens = data;
        },
        setCurrentBlockchain(state, data) {
            state.currentBlockchain = data;
        },
    },
    actions: {
        updateLanguage({ commit }, lang) {
            commit("setLanguage", lang);
            localStorage.setItem("lang", lang);
        },
    },
    modules: {},
    getters: {
        getLanguage(state) {
            return state.lang;
        },
        getFarmTokens(state) {
            return state.farmTokens;
        },
        getStakeTokens(state) {
            return state.stakeTokens;
        },
        getTokensTotalStake(state) {
            return state.tokensTotalStake;
        },
    },
});
