<template>
    <div>
        <div v-if="noWallet" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("INSTALL_WALLET") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <button v-if="getSelecteWallet === 'metamask'" class="metamask modal__button">
                        <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank">
                            <span class="wallet-metamask"></span> Metamask</a
                        >
                    </button>
                    <button v-if="getSelecteWallet === 'onto'" class="metamask modal__button">
                        <a href="https://chrome.google.com/webstore/detail/onto-wallet/ifckdpamphokdglkkdomedpdegcjhjdp?hl=en" target="_blank">
                            <span class="wallet-onto"></span> Onto</a
                        >
                    </button>
                </div>
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("CHOOSE_OTHER_WALLET") }}</p>
                </div>
                <div class="modal__down metamask-wallet">
                    <button v-if="getSelecteWallet !== 'metamask'" @click="setupDesiredWallet('metamask'), $emit('setWalletAndAddress')" class="metamask">
                        <span class="wallet-metamask"></span>
                        Metamask
                    </button>
                    <button v-if="getSelecteWallet !== 'onto'" @click="setupDesiredWallet('onto'), $emit('setWalletAndAddress')" class="metamask">
                        <span class="wallet-onto"></span>
                        Onto
                    </button>
                    <br />
                    <button
                        v-if="getSelecteWallet !== 'walletconnect'"
                        @click="setupDesiredWallet('walletconnect'), $emit('setWalletAndAddress')"
                        class="binance"
                    >
                        <span class="wallet-connect"></span>
                        Wallet Connect
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MultiLang from "@/core/multilang";
    export default {
        props: ["noWallet"],
        data: function () {
            return {
                lang: new MultiLang(this),
            };
        },
        computed: {
            getSelecteWallet() {
                return window.localStorage.getItem("selectedWallet");
            },
        },
        methods: {
            setupDesiredWallet(walletOption) {
                if (walletOption) {
                    window.localStorage.setItem("selectedWallet", walletOption);
                    window.location.reload();
                }
            },
        },
        mounted() {
            this.lang.init();
        },
    };
</script>
