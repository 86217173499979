<template>
    <div class="app__inner" id="home">
        <header-component @connectWallet="$emit('setWalletAndAddress')" @changeWalletRequest="$emit('changeWalletRequest')" />
        <div class="links-main">
            <div class="container-link" data-cy="list-links">
                <div>
                    <a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"><span class="twitter"></span></a>
                </div>
                <div>
                    <a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"><span class="medium"></span></a>
                </div>
                <div>
                    <a href="https://www.youtube.com/channel/UCyG5ZG13kiXvvSeRxdIG_jg" rel="nofollow" target="_blank"><span class="youtube"></span></a>
                </div>
                <div>
                    <a href="https://t.me/bRing_community" target="_blank" rel="nofollow"><span class="telegram"></span></a>
                    <div class="telegram-group">
                        <i class="i-team-line"></i>
                    </div>
                </div>
                <div>
                    <a href="https://t.me/bRing_channel" rel="nofollow" target="_blank"
                        ><span class="telegram speaker"> <i class="i-speaker"></i></span
                    ></a>
                    <div class="telegram-group">
                        <i class="i-volume-up-line"></i>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <section class="main__screen" id="screen_1">
                <div class="responsive">
                    <div class="main__inner">
                        <h1 class="main__h1">
                            {{ lang.get("MAIN_TITLE") }}
                        </h1>
                        <p class="main__text">
                            {{ lang.get("MAIN_DESCRIBE") }}
                        </p>
                        <button
                            @click="$emit('setWalletAndAddress')"
                            v-if="!currentAddress || currentAddress === '0x0000000000000000000000000000000000000000'"
                            class="btn__connect"
                        >
                            {{ lang.get("CONNECT_WALLET") }}
                        </button>
                        <router-link v-else class="get__stake" :to="{ name: 'pool' }"> {{ lang.get("GET_STAKE") }} </router-link>
                    </div>
                </div>
              <div class="responsive-img"></div>
            </section>
            <div class="main">
                <div class="content" id="screen_2">
                    <div class="bacball">
                        <div class="crypto__bank">
                        <div class="responsive">
                          <div class="crypto__bank__inner">
                            <div class="crypto__information">
                              <p class="crypto__title">{{ lang.get("AUDIT_BLOCK_TITLE") }}</p>
                              <p class="crypto__suntitle">
                                {{ lang.get("AUDIT_BLOCK_SUBTITLE") }}
                                <a
                                    href="https://hacken.io/wp-content/uploads/2022/01/BRingFinance_10122021SCAudit_Report_2.pdf"
                                    target="_blank"
                                    class="down-audit"
                                >
                                  Download audit
                                  <i class="i-download-2-line"></i>
                                </a>
                              </p>
                            </div>
                            <div class="gold__bricks"></div>
                            <a href="https://hacken.io/audits/#bring_finance" rel="nofollow" target="_blank" class="more__information">
                              {{ lang.get("MORE_INFO") }}
                            </a>
                          </div>
                        </div>
                      </div>
                        <div class="statistics">
                            <div class="statistics">
                                <div class="responsive">
                                    <div class="statistics__title">
                                        <h2 class="statistics__name">
                                            {{ lang.get("SCREEN_2_TITLE") }}
                                        </h2>
                                        <p class="statistics__subname">
                                            {{ lang.get("SCREEN_2_SUBTITLE") }}
                                        </p>
                                    </div>
                                    <div class="statistics__block">
                                        <div class="statistics__card">
                                            <i class="i-money-dollar-circle-line stat"></i>
                                            <span class="info__num">{{ lang.get("TVL") }}</span>
                                            <p class="statistics__number">3.80M</p>
                                        </div>
                                        <div class="statistics__card">
                                            <i class="i-team-line stat"></i>
                                            <span class="info__num">{{ lang.get("USERS") }}</span>
                                            <p class="statistics__number">6.30K</p>
                                        </div>
                                        <div class="statistics__card">
                                            <i class="i-service-line stat"></i>
                                            <span class="info__num">{{ lang.get("PARTNERS") }}</span>
                                            <p class="statistics__number">30</p>
                                        </div>
                                        <div class="statistics__card">
                                            <i class="i-arrow-left-right-line stat"></i>
                                            <span class="info__num">{{ lang.get("DEFI_INTEGRATIONS") }}</span>
                                            <p class="statistics__number">4</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="stages__screen">
                                <div class="responsive">
                                    <div class="stages__of__work">
                                        <div class="sphere__img"></div>
                                        <div class="sphere__list">
                                            <h3 class="stages__name">{{ lang.get("HOW_IT_WORK") }}</h3>
                                            <p class="stages__subname">
                                                {{ lang.get("HOW_IT_WORK_DESCRIBE") }}
                                            </p>
                                            <div class="sphere__img"></div>
                                            <div class="work__steps">
                                                <div class="ball__wrapper">
                                                    <span class="work__ball__num">1</span>
                                                </div>
                                                <div class="work__steps__inner">
                                                    <h4>{{ lang.get("BUY_TOKEN") }}</h4>
                                                    <span>
                                                        {{ lang.get("BUY_TOKEN_DESCRIBE") }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="work__steps">
                                                <div class="ball__wrapper">
                                                    <span class="work__ball__num">2</span>
                                                </div>
                                                <div class="work__steps__inner">
                                                    <h4>{{ lang.get("STAKE_IN_POOL") }}</h4>
                                                    <span>
                                                        {{ lang.get("STAKE_IN_POOL_DESCRIBE") }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="work__steps">
                                                <div class="ball__wrapper">
                                                    <span class="work__ball__num">3</span>
                                                </div>
                                                <div class="work__steps__inner">
                                                    <h4>{{ lang.get("CLAIM_REWARDS") }}</h4>
                                                    <span>
                                                        {{ lang.get("CLAIM_REWARDS_DESCRIBE") }}
                                                    </span>
                                                </div>
                                            </div>
                                            <router-link
                                                v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'"
                                                class="get__stake"
                                                :to="{ name: 'pool' }"
                                            >
                                                {{ lang.get("GET_STAKE") }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bring__features" id="bRing_features">
                            <div class="responsive">
                                <h4 class="features__title">{{ lang.get("RBING_FUTURES") }}</h4>
                                <p class="features__subtitle">
                                    {{ lang.get("RBING_FUTURES_SUBTITLE") }}
                                </p>
                                <div class="features__card">
                                    <div class="block__card">
                                        <div class="features__icon">
                                            <i class="i-funds-box-line"></i>
                                        </div>
                                        <p class="advantages__title">
                                            {{ lang.get("RBING_FUTURES_1") }}
                                        </p>
                                        <p class="advantages__subtitle">
                                            {{ lang.get("RBING_FUTURES_1_DESCRIBE") }}
                                        </p>
                                    </div>
                                    <div class="block__card">
                                        <div class="features__icon">
                                            <i class="i-bubble-chart-line"></i>
                                        </div>
                                        <p class="advantages__title">
                                            {{ lang.get("RBING_FUTURES_2") }}
                                        </p>
                                        <p class="advantages__subtitle">
                                            {{ lang.get("RBING_FUTURES_2_DESCRIBE") }}
                                        </p>
                                    </div>
                                    <div class="block__card">
                                        <div class="features__icon">
                                            <i class="i-percent-line"></i>
                                        </div>
                                        <p class="advantages__title">
                                            {{ lang.get("RBING_FUTURES_3") }}
                                        </p>
                                        <p class="advantages__subtitle">
                                            {{ lang.get("RBING_FUTURES_3_DESCRIBE") }}
                                        </p>
                                    </div>
                                    <div class="block__card">
                                        <div class="features__icon">
                                            <i class="i-team-line"></i>
                                        </div>
                                        <p class="advantages__title">
                                            {{ lang.get("RBING_FUTURES_4") }}
                                        </p>
                                        <p class="advantages__subtitle">
                                            {{ lang.get("RBING_FUTURES_4_DESCRIBE") }}
                                        </p>
                                    </div>
                                    <div class="block__card">
                                        <div class="features__icon">
                                            <i class="i-creative-commons-nc-line"></i>
                                        </div>
                                        <p class="advantages__title">
                                            {{ lang.get("RBING_FUTURES_5") }}
                                        </p>
                                        <p class="advantages__subtitle">
                                            {{ lang.get("RBING_FUTURES_5_DESCRIBE") }}
                                        </p>
                                    </div>
                                    <div class="block__card">
                                        <div class="features__icon">
                                            <i class="i-briefcase-2-line"></i>
                                        </div>
                                        <p class="advantages__title">
                                            {{ lang.get("RBING_FUTURES_6") }}
                                        </p>
                                        <p class="advantages__subtitle">
                                            {{ lang.get("RBING_FUTURES_6_DESCRIBE") }}
                                        </p>
                                    </div>
                                    <div class="block__card">
                                        <div class="features__icon">
                                            <i class="i-lock-unlock-line"></i>
                                        </div>
                                        <p class="advantages__title">
                                            {{ lang.get("RBING_FUTURES_7") }}
                                        </p>
                                        <p class="advantages__subtitle">
                                            {{ lang.get("RBING_FUTURES_7_DESCRIBE") }}
                                        </p>
                                    </div>
                                    <div class="block__card">
                                        <div class="features__icon">
                                            <i class="i-user-follow-line"></i>
                                        </div>
                                        <p class="advantages__title">
                                            {{ lang.get("RBING_FUTURES_8") }}
                                        </p>
                                        <p class="advantages__subtitle">
                                            {{ lang.get("RBING_FUTURES_8_DESCRIBE") }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <router-link
                                v-if="currentAddress && currentAddress !== '0x0000000000000000000000000000000000000000'"
                                class="get__stake center"
                                :to="{ name: 'pool' }"
                            >
                                {{ lang.get("START_STAKING") }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="our__partners">
                    <div class="responsive">
                        <h5 class="partners__title">
                            {{ lang.get("PARTNERS") }}
                        </h5>
                        <p class="partners__subtitle">
                            {{ lang.get("PARTNERS_SUBTITLE") }}
                        </p>
                        <div class="partners__inner">
                            <div class="partners__row">
                                <a
                                    href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xf7413489c474ca4399eee604716c72879eea3615&use=V2"
                                    rel="nofollow"
                                    class="apyswap"
                                    target="_blank"
                                ></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://yellowroad.app/" rel="nofollow" class="yeallowroad" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://launchpad.a2dao.com/" rel="nofollow" class="a2dao" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://kyros.ventures/" rel="nofollow" class="kyros" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a
                                    href="https://app.uniswap.org/#/swap?outputCurrency=0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54&use=V2"
                                    rel="nofollow"
                                    class="hapi"
                                    target="_blank"
                                ></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://1mlnnfts.com/" rel="nofollow" class="mil_ntf" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://peanut.trade/" rel="nofollow" class="peanut" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.raze.network/" rel="nofollow" class="raze" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://ferrum.network/" rel="nofollow" class="ferrum" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href=" https://www.coingecko.com/en/coins/mu-dank" rel="nofollow" class="memeunity" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://dehive.finance/" rel="nofollow" class="dehive" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://coinmarketcap.com/currencies/bring-finance/" rel="nofollow" class="coinmarket" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.coingecko.com/en/coins/bulk-network" rel="nofollow" class="bulknetwork" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.hedget.com/" rel="nofollow" class="hadge" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://berrydata.co/#/home" rel="nofollow" class="berry" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://babylons.io/" rel="nofollow" class="babylons" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.umb.network/" rel="nofollow" class="umb" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://rocketx.exchange/" rel="nofollow" class="rocketx" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://weld.money/" rel="nofollow" class="weld" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://app.usemate.com/" rel="nofollow" class="usemate" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.gamyfi.org/home" rel="nofollow" class="gamfy" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://yarloo.io/" rel="nofollow" class="yarloo" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.smaugs.com/" rel="nofollow" class="smaugs" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://ibetyou.xyz/" rel="nofollow" class="betyou" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.lokr.io/" rel="nofollow" class="polka" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.gamestation.io/" rel="nofollow" class="game-st" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://teneo.finance/" rel="nofollow" class="teneo" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://skyrim.finance/" rel="nofollow" class="skyrim" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://www.fintropy.io/" rel="nofollow" class="fin" target="_blank"></a>
                            </div>
                            <div class="partners__row">
                                <a href="https://bitkeep.com/" rel="nofollow" class="bit" target="_blank"></a>
                            </div>
                        </div>
                    </div>
                    <!-- <button class="show__more">
                            {{ lang.get("SHOW_MORE") }}
                        </button> -->
                </div>
            </div>
            <div class="roadmap" id="screen_3">
                <div class="responsive">
                    <div class="roadmap__inner">
                        <h6 class="roadmap__title">{{ lang.get("ROADMAP") }}</h6>
                        <p class="roadmap__subtitle">
                            {{ lang.get("ROADMAP_DESCRIPTION") }}
                        </p>
                        <div class="years__roadmap">
                            <button @click="selectedRoad = !selectedRoad" :class="{ active: !selectedRoad }">{{ lang.get("ROADMAP") }} 2021</button>
                            <button @click="selectedRoad = !selectedRoad" :class="{ active: selectedRoad }">{{ lang.get("ROADMAP") }} 2022</button>
                        </div>
                    </div>
                    <div class="road__steps">
                        <div class="responsive">
                            <ul v-if="!selectedRoad" class="steps__ul">
                                <li class="wrapper__q__steps">
                                    <div class="q__steps">
                                        <span class="count">q1</span>
                                    </div>
                                    <div class="steps__name">{{ lang.get("Q1_TITLE_21") }}</div>
                                    <div class="steps__desc">
                                        <ul v-html="lang.get('ROADMAP_DESC_1_21')"></ul>
                                    </div>
                                </li>
                                <li class="wrapper__q__steps">
                                    <div class="q__steps">
                                        <span class="count">q2</span>
                                    </div>
                                    <div class="steps__name">{{ lang.get("Q2_TITLE_21") }}</div>
                                    <div class="steps__desc">
                                        <ul v-html="lang.get('ROADMAP_DESC_2_21')"></ul>
                                    </div>
                                </li>
                                <li class="wrapper__q__steps">
                                    <div class="q__steps">
                                        <span class="count">q3</span>
                                    </div>
                                    <div class="steps__name">{{ lang.get("Q3_TITLE_21") }}</div>
                                    <div class="steps__desc">
                                        <ul v-html="lang.get('ROADMAP_DESC_3_21')"></ul>
                                    </div>
                                </li>
                                <li class="wrapper__q__steps">
                                    <div class="q__steps">
                                        <span class="count">q4</span>
                                    </div>
                                    <div class="steps__name">{{ lang.get("Q4_TITLE_21") }}</div>
                                    <div class="steps__desc">
                                        <ul v-html="lang.get('ROADMAP_DESC_4_21')"></ul>
                                    </div>
                                </li>
                            </ul>
                            <ul v-else class="steps__ul">
                                <li class="wrapper__q__steps">
                                    <div class="q__steps">
                                        <span class="count">q1</span>
                                    </div>
                                    <div class="steps__name">{{ lang.get("Q1_TITLE_22") }}</div>
                                    <div class="steps__desc">
                                        <ul v-html="lang.get('ROADMAP_DESC_1_22')"></ul>
                                    </div>
                                </li>
                                <li class="wrapper__q__steps">
                                    <div class="q__steps">
                                        <span class="count">q2</span>
                                    </div>
                                    <div class="steps__name">{{ lang.get("Q2_TITLE_22") }}</div>
                                    <div class="steps__desc">
                                        <ul v-html="lang.get('ROADMAP_DESC_2_22')"></ul>
                                    </div>
                                </li>
                                <li class="wrapper__q__steps">
                                    <div class="q__steps">
                                        <span class="count">q3</span>
                                    </div>
                                    <div class="steps__name">{{ lang.get("Q3_TITLE_22") }}</div>
                                    <div class="steps__desc">
                                        <ul v-html="lang.get('ROADMAP_DESC_3_22')"></ul>
                                    </div>
                                </li>
                                <li class="wrapper__q__steps">
                                    <div class="q__steps">
                                        <span class="count">q4</span>
                                    </div>
                                    <div class="steps__name">{{ lang.get("Q4_TITLE_22") }}</div>
                                    <div class="steps__desc">
                                        <ul v-html="lang.get('ROADMAP_DESC_4_22')"></ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="road__img"></div>
            </div>
            <div class="screen_multichain">
                <div class="responsive">
                    <div class="multi__bank">
                        <h6 class="partners__title">
                            {{ lang.get("POWERED_BY_TITLE") }}
                        </h6>
                        <p class="partners__subtitle">
                            {{ lang.get("POWERED_BY_DESCRIPTION") }}
                        </p>
                        <div class="partners__inner">
                            <div class="partners__row">
                                <a class="blockchain blockch_eth"></a>
                            </div>
                            <div class="partners__row">
                                <a class="blockchain blockch_bnb"></a>
                            </div>
                            <div class="partners__row">
                                <a class="blockchain blockch_harmony"></a>
                            </div>
                            <div class="partners__row">
                                <a class="blockchain blockch_polygon"></a>
                            </div>
                        </div>
                        <!-- <button class="multi__btn">
                                {{ lang.get("SHOW_MORE") }}
                            </button> -->
                    </div>
                </div>
            </div>
            <div class="started">
                <div class="responsive">
                    <div class="started__iner">
                        <div class="started__left">
                            <h6 class="started__title">{{ lang.get("GET_STARTED_TITLE") }}</h6>
                            <p class="started__subtitle">
                                {{ lang.get("GET_STARTED_SUBTITLE") }}
                            </p>
                            <a href="https://bring.finance/partners.html" target="_blank" class="started__btn">{{ lang.get("ADD_PROJECT") }}</a>
                        </div>
                        <div class="started__right"></div>
                    </div>
                </div>
            </div>
            <section class="section_faq" itemtype=“https://schema.org/FAQPage”>
                <div class="responsive">
                    <h2 class="faq__title">{{ lang.get("FAQs") }}</h2>
                    <faq-blocks :faqs="faqs" @openFaq="openFaq($event)" />
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import MultiLang from "@/core/multilang";
    import FaqBlocks from "../components/FaqBlocks.vue";
    import { mapState } from "vuex";
    import HeaderComponent from "../components/HeaderComponent.vue";

    export default {
        components: {
            FaqBlocks,
            HeaderComponent,
        },

        computed: {
            ...mapState(["currentAddress"]),
            addressShort() {
                if (this.currentAddress) {
                    return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length);
                }
            },
        },
        data: function () {
            return {
                selectedRoad: true,
                lang: new MultiLang(this),
                faqs: [
                    {
                        title: "FAQ_1_TITLE",
                        description: "FAQ_1_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_2_TITLE",
                        description: "FAQ_2_TEXT",
                        isOpen: false,
                    },
                    /*{
          title: "FAQ_3_TITLE",
          description:
            "FAQ_3_TEXT",
          isOpen: false,
        },*/
                    {
                        title: "FAQ_4_TITLE",
                        description: "FAQ_4_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_5_TITLE",
                        description: "FAQ_5_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_6_TITLE",
                        description: "FAQ_6_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_7_TITLE",
                        description: "FAQ_7_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_8_TITLE",
                        description: "FAQ_8_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_9_TITLE",
                        description: "FAQ_9_TEXT",
                        isOpen: false,
                    },
                    {
                        title: "FAQ_10_TITLE",
                        description: "FAQ_10_TEXT",
                        isOpen: false,
                    },
                ],
            };
        },
        mounted() {
            this.lang.init();
        },
        methods: {
            //TODO change when deployed to binance
            connectWallet() {
                try {
                    let currentAccount = localStorage.getItem("address");
                    if (window.ethereum) {
                        window.ethereum
                            .request({ method: "eth_requestAccounts" })
                            .then(handleAccountsChanged)
                            .catch((err) => {
                                if (err.code == "-32002") {
                                    alert("Proceed to your metamask extension");
                                }
                            });

                        // Note that this event is emitted on page load.
                        // If the array of accounts is non-empty, you're already
                        // connected.
                        window.ethereum.on("accountsChanged", handleAccountsChanged);

                        // For now, 'eth_accounts' will continue to always return an array
                        function handleAccountsChanged(accounts) {
                            if (accounts.length === 0) {
                                localStorage.removeItem("address");
                                _this.$store.commit("setCurrentAddress", "0x0000000000000000000000000000000000000000");

                                // MetaMask is locked or the user has not connected any accounts
                                // alert('Please connect to MetaMask.');
                            } else if (accounts[0] !== currentAccount) {
                                currentAccount = accounts[0];
                                localStorage.setItem("address", currentAccount);
                                // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                                location.reload();
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            openFaq(index) {
                this.faqs.map((val, key) => {
                    if (val.isOpen && key === index) val.isOpen = false;
                    else val.isOpen = key === index;
                    return val;
                });
            },
        },
    };
</script>
