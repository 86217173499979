<template>
  <div>
    <header-component/>
    <div class="container">
      <div class="responsive">
        <div class="term-of-use">
          <h1>TERMS AND CONDITIONS</h1>
          <p class="disc-privacy-policy">
            <b class="wellcome-brng">Welcome to bRing Finance!</b>
            <br>
            These terms and conditions outline the rules and regulations for the use of ElephantsLab, LCC's Website, located at https://bring.finance/.<br>
            By accessing this website we assume that you accept these terms and conditions. Do not continue to use bRing Finance if you do not agree to accept
            all of the terms and conditions stated on this page.
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice, and all Agreements: "Client",
            "You" and "Your" refers to you, the person logged on this website and compliant to
            the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us",
            refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves.
            Before using our Services, please read the Terms and Conditions carefully, along with any other
            policies or notices on bRing Finance’s website or mobile applications, and our Privacy Policy.
            Together, these materials contain terms, rules, and guidelines related to your use of bRing Finance.
          </p>
          <div>
            <p class="name-privacy-policy">HOW YOU ACCEPT THIS POLICY</p>
            <div>
              <p class="disc-privacy-policy">
                By creating or importing a locally hosted wallet (a “Wallet”), or visiting our website,
                you acknowledge that you have read, understood, and agreed to these Terms and Conditions,
                as well as the accompanying Privacy Policy. We reserve the right to change these Terms and Conditions
                at any time. Any such changes with respect to your use of bRing Finance will take effect immediately
                when posted on our website or on our mobile application. Your continued use of bRing Finance following
                any such change will signify your acceptance to be bound by the then current Terms and Conditions. Please
                check the effective date above to determine if there have been any changes since you have last reviewed these
                Terms and Conditions. If you do not agree to this Terms or any modifications to this Terms, you should not use bRing Finance. Failure or
                delay by bRing Finance in enforcing or
                partially enforcing any provision of these Terms shall not be construed as a waiver of any rights.
              </p>
            </div>
            <p class="name-privacy-policy">THE BLOCKCHAIN SERVICES</p>
            <div>
              <p class="disc-privacy-policy">
                bRing is a decentralized cross-chain platform with unique DeFi features, like liquidity staking and yield farming. This is the second version of bRing, bRing 2.0.
                <br>
                Our previous version specialized in farming pools only. We now offer several staking pool options. Single token staking, which are token-to-token pools where you stake one token to get the same token. Liquidity staking, where you can stake LP tokens and receive tokens as a reward. Farming pools, where multiple projects (up to 10) come together in one pool and you can stake one token or LP token from participating projects and receive all tokens in the pool as a reward.
                <br>
                Our previous version provided us with a deeper understanding of DeFi and staking, as well as with practical experience. We now better understand the problems facing young projects, namely, in development and promotion. Our staking pools can help grow interest for their token, boost its liquidity, and create demand.
                <br>
                We provide a ready-made solution to projects. bRing provides full tech support backed by a comprehensive audit from Hacken.
              </p>
            </div>
            <div>
              <p class="disc-privacy-policy name">Staking</p>
              <p class="disc-privacy-policy">
                The main difference between bRing and bRing 2.0 is that you can now unclaim and
                unstake separately. First stake, then claim your rewards before unstaking. Here’s
                how it works step by step.
                Stake. First, choose which pools you want to stake in. Usually, pools last 90 days.
                You can stake from the time the smart contract is deployed on the blockchain until the moment it closes.
                Claim.You can claim staking rewards anytime you like, as long as you haven’t unstaked
                already. If you want to claim a reward before the pool closes and there is a penalty system in this pool, you won’t get your full reward. This is explained further in “Reward Mechanics” and “Penalty System” below.
                Unstake. You can claim rewards and close your stake anytime, as long as it isn’t unstaked yet,
                even after 90 days from the time the smart contract was deployed.
              </p>
            </div>
            <div>
              <p class="disc-privacy-policy name">Reward Mechanics</p>
              <p class="disc-privacy-policy">
                Each pool has a set number of tokens, which are shared as rewards between all pool participants at that time. The size of each participant’s reward is directly proportional to the size of their stake and how long they staked for, and inversely proportional to the total amount of staked tokens in the pool. The smart contract knows when you start to stake, the size of your stake, and the number of days before your unstake or claim.
              </p>
            </div>
            <div>
              <p class="disc-privacy-policy name">Penalty System</p>
              <p class="disc-privacy-policy">
                In order to motivate our users to keep their stakes in the pool, we decided to include a penalty system for unstaking early. Each pool’s penalty fees will be determined among participating projects. Even though you can unstake early, you won’t get full rewards on your stake unless you stay in the pool the full 90 days. The penalty only applies to the reward, not to your stake. You are free to retrieve your stake at any time without penalty.
                There are reminders in place on the platform to keep you from unstaking early. If you look in the “Unstake” and “Claim’’ windows, you’ll notice a warning that looks something like this, “Please note that you will be penalized for unstaking before 00/00/00”, displaying the date you must wait until to receive your full reward. The date will be different for each pool.
              </p>
            </div>
            <div>
              <p class="disc-privacy-policy name">Referral Program</p>
              <p class="disc-privacy-policy">
                Our new system gives you and your referrals more opportunities to earn. Now you can earn rewards from 3 sources instead of 1. The more you and your referrals stake, the bigger your rewards will be.
                Our referral program has three levels: 1st, 2nd, and 3rd level referrals. You receive rewards from your referral’s staking reward amount. The reward size depends on their level and the amount of their staking reward. You will find your referral link on the referral page.
                For example, you invite someone to stake. They are your 1st level referral. Then they invite someone else, that person is your 2nd level referral. Then that person invites someone, they are your 3rd level referral.
                You receive 3% of your 1st level referral’s staking reward, 2% of your 2nd level referral’s staking reward, and 1% of your 3rd level referral’s staking reward. The user that stakes using the referral link also receives an additional 4% to their reward. Rewards are distributed to your wallet the moment each of your referrals unstakes.
              </p>
            </div>
            <p class="name-privacy-policy">SECURITY AND RISKS</p>
            <div>
              <p class="disc-privacy-policy">
                <b>Audit</b>
                In decentralized finance, users trust the code itself to keep them safe instead of some outside authority. Although this is a major
                advantage of blockchain, it
                can also be a risk. A blockchain is only as safe as its smart contracts. We’ve audited our smart contracts to ensure our users’ safety.
                bRing 2.0 was audited by Hacken, a security company that specializes in blockchain. They gave the smart contract a high rating,
                “Well-secured” in the audit results and only found low-severity issues. Anyone is free to check the official results on Hacken’s website.
                <b>Depreciation</b>
                As with any cryptocurrency, coin prices change and can rise or fall depending on the market. This is completely normal and
                shouldn’t stop you from using cryptocurrencies.
              </p>
            </div>
            <p class="name-privacy-policy">LIMITATION OF LIABILITY & DISCLAIMER OF WARRANTIES</p>
            <div>
              <p class="disc-privacy-policy">
                You understand and agree that we have no control over, and no duty to take any action regarding: Failures, disruptions,
                errors, or delays in processing Virtual Currency that you may experience while using the Services; The risk of failure
                of hardware, software, and Internet connections; The risk of malicious software being introduced or found in the software
                underlying bRing Finance; The risk that third parties may obtain unauthorized access to information stored within your Wallet,
                including, but not limited to your Wallet address, private key, and mnemonic (backup) phrase; and The risk of unknown vulnerabilities
                in or unanticipated changes to the Ethereum Networks. You release us from all liability related to any losses, damages, or claims arising
                from: (a) user error such as forgotten passwords, incorrectly constructed transactions, or mistyped Virtual Currency addresses; (b) server
                failure or data loss; (c) unauthorized access to the bRing Finance application; (d) bugs or other errors in the bRing Finance software; and
                (e) any unauthorized third party activities, including, but not limited to, the use of viruses, phishing, brute forcing, or other means of
                attack against bRing Finance. We make no representations concerning any Third Party Content contained in or accessed through our Services.
                Any other terms, conditions, warranties,
                or representations associated with such content, are solely between you and such organizations and/or individuals
              </p>
            </div>
            <p class="name-privacy-policy">COOKIES, HYPERLINKING TO OUR CONTENT AND CONTENT LIABILITY</p>
            <div>
              <p class="disc-privacy-policy">
                We employ the use of cookies. By accessing bRing Finance, you agreed to use cookies in agreement with ElephantsLab, LCC's Privacy Policy.
                Most interactive websites use cookies to let
                us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make
                it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies. The following organizations may
                link to our Website without prior written approval: (a) search engines; (b) news organizations; (с) online directory distributors may link to
                our Website in the same manner as they hyperlink to the Websites of other listed businesses.
                These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way
                deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c)
                fits within the context of the linking party’s site. We may consider and approve other link requests from the following types of organizations:
                (a) commonly-known consumer and/or business information sources; (b) associations or other groups representing charities; (с) online directory
                distributors; (d) internet portals.
                These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.
                We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that
                arise on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes,
                otherwise violates, or advocates the infringement or other violation of, any third party rights.
              </p>
            </div>
            <p class="name-privacy-policy">TIME LIMITATION ON CLAIMS</p>
            <div>
              <p class="disc-privacy-policy">
                You agree that any claim you may have arising out of or
                related to your relationship with us must be filed within one year after such claim arises, otherwise, your claim is permanently barred.
              </p>
            </div>
            <p class="name-privacy-policy">TERMINATION</p>
            <div>
              <p class="disc-privacy-policy">
                In the event of termination concerning your license to use bRing Finance, your obligations under this Agreement will still continue.
                Your access to the funds in your Wallet after termination will depend on your access to your backup of your Wallet address and private key.
              </p>
            </div>
            <p class="name-privacy-policy">DISCONTINUANCE OF SERVICES</p>
            <div>
              <p class="disc-privacy-policy">
                We may, in our sole discretion and without your consent,
                with or without prior notice, and at any time, modify or discontinue, temporarily or permanently, any portion of our Services. You are solely responsible for storing outside of the Services a backup of any Wallet address and private key pair that you maintain in your Wallet. Maintaining an external backup of any Wallet address and private key pairs associated with your Wallet will allow you to access the Ethereum Network, BNB Chain, Harmony upon which your Wallet is secured. Such a backup will allow the user to fully restore their Wallet at any time without cost or loss of the user’s Virtual Currency. If you do not maintain a backup of your Wallet data outside of the Services, you will not be able to access the Virtual Currency associated with your Wallet.
                bRing Financeshall not be held responsible or liable for any loss of Virtual Currency
                in the event that we discontinue or depreciate the Services.
              </p>
            </div>
            <p class="name-privacy-policy">FORCE MAJEURE</p>
            <div>
              <p class="disc-privacy-policy">
                We shall not be held liable for any delays, failure in performance, or interruptions of service which directly or indirectly result from any cause or condition beyond our reasonable control, including but not limited to: any delay or failure due to any act of God, act of civil or military authorities, act of terrorism, civil disturbance, war, strike or other labor dispute, fire, interruption in telecommunications or Internet services or network provider services, failure of equipment and/or software, other catastrophe, or any other occurrence which is beyond our reasonable control and shall not affect the validity and enforceability of any remaining provisions.
              </p>
            </div>
            <p class="name-privacy-policy">ASSIGNMENT</p>
            <div>
              <p class="disc-privacy-policy">
                You agree that we may assign any of our rights and/or transfer, sub-contract, or delegate any of our obligations under these Terms.
                Your agreement to these Terms is personal to you and you may not transfer or assign it to any third party.
              </p>
            </div>
            <p class="name-privacy-policy">ENTIRE AGREEMENT</p>
            <div>
              <p class="disc-privacy-policy">
                This Agreement sets forth the entire understanding and agreement as to the subject matter hereof and supersedes
                any and all prior discussions, agreements, and understandings of any kind (including, without limitation,
                any prior versions of this Agreement) and every nature between us.
                Except as provided for above, any modification to this Agreement must be in writing and must be signed by both parties.
              </p>
            </div>
            <p class="name-privacy-policy">QUESTIONS OR COMMENTS</p>
            <div>
              <p class="disc-privacy-policy">
                We welcome comments, questions, concerns, or suggestions. Please send us a message on our contact page at team@bring.finance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";

export default {
  components: {
    HeaderComponent,
  },
  name: "TermOfUse"
}
</script>

<style scoped>

</style>