<template>
    <div class="wrapper__505">
        <div class="wrapp_error">
            <h1>500</h1>
            <p>Internal Server Error</p>
            <a href="https://t.me/bRing_channel" target="_blank">Chat</a>
        </div>
    </div>
</template>
<style scoped>
body {

  height: 100vh;
  background-size: cover;
  text-align: center;
  display: flex;
  margin: 0;
  justify-content: center;
  font-family: "Titillium Web", sans-serif;
}

.wrapper__505.app__inner {
  background-image: url("../assets/images/505.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}



.wrapp_error {
  text-align: center;
}

.wrapper__505 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 100vh;
}

h1 {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 149px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

p {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 25px 0 25px 0;
}

a {
  padding: 12px 24px;
  margin-top: 24px;
  text-decoration: none;
  width: fit-content;
  height: 44px;

  background: #d9113a;
  border-radius: 10px;

  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;

  text-transform: uppercase;

  color: #ffffff;
  border: none;
}
</style>

<script>
    export default {
        name: "ServerError",
    };
</script>

