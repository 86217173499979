import { ethers } from "ethers";
import conf from "../../Config.json";
import axios from "axios";
import { V3 } from "../abi/V3";
import { lpToken } from "../abi/lpToken";
import WalletConnectProvider from "@walletconnect/web3-provider";
const timer = (ms) => new Promise((res) => setTimeout(res, ms));

export default class Core {
    constructor(vueContext) {
        this.context = vueContext;
        this.baseURL = conf.BASE_URL;
        this.init();
    }
    async checkAccount(accountType) {
        if (window.ethereum && accountType === "metamask") {
            const connected = window.ethereum
                .request({ method: "eth_requestAccounts" })
                .then(handleAccountsChanged)
                .then((res) => res)
                .catch((err) => {
                    console.error(err);
                    if (err.code === 4001) {
                        window.localStorage.removeItem("address");
                        window.localStorage.removeItem("selectedWallet");
                        return;
                    }
                });

            // Note that this event is emitted on page load.
            // If the array of accounts is non-empty, you're already
            // connected.
            window.ethereum.on("accountsChanged", handleAccountsChanged);

            // For now, 'eth_accounts' will continue to always return an array
            function handleAccountsChanged(accounts) {
                if (accounts.length === 0) {
                    return false;
                    // MetaMask is locked or the user has not connected any accounts
                    // alert('Please connect to MetaMask.');
                } else if (accounts.length > 0) {
                    return true;
                }
            }
            return connected;
        } else if (window.ethereum && accountType === "onto") {
            const connected = window.onto
                .request({ method: "eth_requestAccounts" })
                .then(handleAccountsChanged)
                .then((res) => res)
                .catch((err) => {
                    console.error(err);
                    if (err.code === 4001) {
                        window.localStorage.removeItem("address");
                        window.localStorage.removeItem("selectedWallet");
                        return;
                    }
                });

            // Note that this event is emitted on page load.
            // If the array of accounts is non-empty, you're already
            // connected.
            window.onto.on("accountsChanged", handleAccountsChanged);
            function handleAccountsChanged(accounts) {
                if (accounts.length === 0) {
                    return false;
                    // MetaMask is locked or the user has not connected any accounts
                    // alert('Please connect to MetaMask.');
                } else if (accounts.length > 0) {
                    return true;
                }
            }
            return connected;
        }
    }

    async init() {
        if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
            const rpc = {
                1: "https://ethnode.cloud/",
            };
            for (let chainId of conf.SUPPORTED_BLOCKCHAINS) {
                const url = conf.NETWORK_PARAMS.find((el) => Number(el.chainId) === Number(chainId))?.params.rpcUrls[0];
                rpc[chainId] = url;
            }

            this.providerInstance = new WalletConnectProvider({
                rpc: rpc,
            });

            if (!this.providerInstance.connected) {
                try {
                    // Create a dialogue
                    await this.providerInstance.enable();
                } catch (error) {
                    if (error.message === "User closed modal") {
                        window.localStorage.removeItem("address");
                        window.localStorage.removeItem("walletconnect");
                        window.localStorage.removeItem("selectedNetwork");
                        window.localStorage.removeItem("selectedWallet");
                    }
                }
            }
            await this.subscribeToEvents();
            if (!this.currentBlockchain) {
                this.currentBlockchain = window.localStorage.getItem("selectedNetwork");
            }

            const otherNetworks = conf.SUPPORTED_BLOCKCHAINS.filter((el) => Number(el) !== Number(this.currentBlockchain));
            this.otherNetworkContractData = {};
            for (let network of otherNetworks) {
                this.otherNetworkContractData[network] = conf[network];
            }

            const isSupported = conf.SUPPORTED_BLOCKCHAINS.find((networkId) => networkId === Number(this.currentBlockchain));

            if (isSupported) {
                this.Config = conf[this.currentBlockchain];
                console.log(this.currentBlockchain);
                this.contractAddresses = conf[this.currentBlockchain].CONTRACTS.map((el) => el.ADDRESS.toLowerCase());
                const contracts = this.Config.CONTRACTS;
                this.farmAdminAddress = this.Config.FARM_ADMIN_ADDRESS;
                this.defaultReferrer = this.Config.DEFAULT_REFERRER;

                if (!this.N1) {
                    if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                        this.provider = new ethers.providers.Web3Provider(this.providerInstance);

                        this.signer = this.provider.getSigner();

                        for (let [index, contractDetails] of contracts.entries()) {
                            this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, this.provider).connect(this.signer);
                        }
                        return this.N1;

                        // this.provider = provider;
                        // this.signer = provider.getSigner();
                        // for (let [index, contractDetails] of contracts.entries()) {
                        //     this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, provider).connect(provider);
                        // }

                        // return this.N1;
                    } else {
                        let provider = new ethers.providers.JsonRpcProvider(conf.PRIMARY_BLOCKCHAIN.node);

                        this.provider = provider;
                        for (let [index, contractDetails] of contracts.entries()) {
                            this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, provider).connect(provider);
                        }

                        return this.N1;
                    }
                }

                return this.N1;
            } else {
                this.provider = new ethers.providers.Web3Provider(this.providerInstance);

                // for (let [index, contractDetails] of contracts.entries()) {
                //     this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, this.provider).connect(this.provider);
                // }

                return false;
            }
        } else if (window.localStorage.getItem("selectedWallet") === "metamask") {
            let blockchain = Number(window?.ethereum?.chainId);
            if (!blockchain) throw new Error("Metamask 0 chain id");

            const otherNetworks = conf.SUPPORTED_BLOCKCHAINS.filter((el) => Number(el) !== Number(blockchain));
            this.otherNetworkContractData = {};
            for (let network of otherNetworks) {
                this.otherNetworkContractData[network] = conf[network];
            }

            const isSupported = conf.SUPPORTED_BLOCKCHAINS.find((networkId) => networkId === Number(blockchain));
            if (isSupported) {
                this.Config = conf[blockchain];

                this.contractAddresses = conf[blockchain].CONTRACTS.map((el) => el.ADDRESS.toLowerCase());
                const contracts = this.Config.CONTRACTS;
                this.farmAdminAddress = this.Config.FARM_ADMIN_ADDRESS;
                this.defaultReferrer = this.Config.DEFAULT_REFERRER;

                if (!this.N1) {
                    if (window.localStorage.getItem("selectedWallet") === "metamask" && window.ethereum) {
                        this.currentBlockchain = Number(blockchain);

                        this.context.$store.commit("setCurrentBlockchain", this.currentBlockchain);

                        let provider = new ethers.providers.Web3Provider(window.ethereum);
                        // const connected = await ethereum._metamask.isUnlocked()
                        const connected = await this.checkAccount("metamask");

                        if (connected) {
                            this.provider = provider;
                            this.signer = provider.getSigner();
                            for (let [index, contractDetails] of contracts.entries()) {
                                this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, provider).connect(this.signer);
                            }

                            return this.N1;
                        }

                        this.provider = provider;
                        this.signer = provider.getSigner();
                        for (let [index, contractDetails] of contracts.entries()) {
                            this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, provider).connect(provider);
                        }

                        return this.N1;
                    } else {
                        let provider = new ethers.providers.JsonRpcProvider(conf.PRIMARY_BLOCKCHAIN.node);

                        this.provider = provider;
                        for (let [index, contractDetails] of contracts.entries()) {
                            this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, provider).connect(provider);
                        }

                        return this.N1;
                    }
                }

                return this.N1;
            }
        } else if (window.localStorage.getItem("selectedWallet") === "onto") {
            let blockchain = Number(window?.onto?.chainId);
            if (!blockchain) throw Error("Onto 0 chain id");

            const otherNetworks = conf.SUPPORTED_BLOCKCHAINS.filter((el) => Number(el) !== Number(blockchain));
            this.otherNetworkContractData = {};
            for (let network of otherNetworks) {
                this.otherNetworkContractData[network] = conf[network];
            }

            const isSupported = conf.SUPPORTED_BLOCKCHAINS.find((networkId) => networkId === Number(blockchain));

            if (isSupported) {
                this.Config = conf[blockchain];

                this.contractAddresses = conf[blockchain].CONTRACTS.map((el) => el.ADDRESS.toLowerCase());
                const contracts = this.Config.CONTRACTS;
                this.farmAdminAddress = this.Config.FARM_ADMIN_ADDRESS;
                this.defaultReferrer = this.Config.DEFAULT_REFERRER;

                if (!this.N1) {
                    if (window.localStorage.getItem("selectedWallet") === "onto" && window.onto) {
                        this.currentBlockchain = Number(blockchain);

                        this.context.$store.commit("setCurrentBlockchain", this.currentBlockchain);

                        let provider = new ethers.providers.Web3Provider(window.onto);
                        // const connected = await ethereum._metamask.isUnlocked()

                        const connected = await this.checkAccount("onto");

                        if (connected) {
                            this.provider = provider;
                            this.signer = provider.getSigner();
                            for (let [index, contractDetails] of contracts.entries()) {
                                this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, provider).connect(this.signer);
                            }

                            return this.N1;
                        }
                        console.log(connected);
                        this.provider = provider;
                        this.signer = provider.getSigner();
                        for (let [index, contractDetails] of contracts.entries()) {
                            this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, provider).connect(provider);
                        }

                        return this.N1;
                    } else {
                        let provider = new ethers.providers.JsonRpcProvider(conf.PRIMARY_BLOCKCHAIN.node);

                        this.provider = provider;
                        for (let [index, contractDetails] of contracts.entries()) {
                            this[`N${index + 1}`] = new ethers.Contract(contractDetails.ADDRESS, V3, provider).connect(provider);
                        }

                        return this.N1;
                    }
                }

                return this.N1;
            }
        }
    }

    getSiteData(period = 60000) {
        let _this = this;

        setTimeout(async function tick() {
            try {
                const selectedWallet = window.localStorage.getItem("selectedWallet");
                const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                const blockchain =
                    selectedWallet === "metamask"
                        ? Number(window?.ethereum?.chainId)
                        : selectedWallet === "walletconnect"
                        ? Number(WC_Obj?.chainId)
                        : selectedWallet === "onto"
                        ? Number(window?.onto?.chainId)
                        : 0;
                const isSupported = conf.SUPPORTED_BLOCKCHAINS.find((networkId) => networkId === blockchain);

                if (isSupported) {
                    // let totalTokensNumber = await _this.totalTokensCount()
                    // let farmTokens = await _this.getFarmingTokens()
                    let stakeTokens = _this.Config.stakeTokens;
                    let tokens = _this.Config.tokens;
                    let tokensTotalStake = {};
                    let penaltyInfo = {};
                    const data = await _this.getTotalStaking();
                    const allContractsData = await _this.getContractsData();

                    for (let [index, address] of _this.contractAddresses.entries()) {
                        tokensTotalStake[`N${index + 1}`] = data[`N${index + 1}TotalStake`];
                        penaltyInfo[`N${index + 1}`] = data[`N${index + 1}PenaltyInfo`];
                    }

                    // _this.context.$store.commit('setTotalTokensNumber', totalTokensNumber);
                    _this.context.$store.commit("setStakeTokens", stakeTokens);
                    _this.context.$store.commit("setTokensTotalStake", tokensTotalStake);
                    _this.context.$store.commit("setPoolPenaltyInfo", penaltyInfo);
                    _this.context.$store.commit("setAllContractsData", allContractsData);
                } else {
                    // let totalTokensNumber = await _this.totalTokensCount()
                    // let farmTokens = await _this.getFarmingTokens()

                    const allContractsData = await _this.getContractsData();

                    // _this.context.$store.commit('setTotalTokensNumber', totalTokensNumber);

                    _this.context.$store.commit("setAllContractsData", allContractsData);
                }

                setTimeout(tick, period);
            } catch (ex) {
                console.log(ex);
                if (!_this.Config) {
                    await _this.init();
                }
                setTimeout(tick, 300);
            }
        }, 300);
    }

    getCurrentRate(period = 60000 /* miliseconds */) {
        let _this = this;
        setTimeout(async function tick() {
            let currentRate;
            const now = new Date();
            const requestTimestampUtc = Date.UTC(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate(),
                now.getUTCHours(),
                now.getUTCMinutes(),
                now.getUTCSeconds(),
                now.getUTCMilliseconds()
            );
            let isExist = true;
            const tokens = _this.Config.stakeTokens;
            const savedTokensRate = JSON.parse(localStorage.getItem("rate")) || {};
            for (let token of tokens) {
                isExist = Object.keys(savedTokensRate).indexOf(token.address.toLowerCase()) < 0 ? false : isExist;
            }
            try {
                if (localStorage.getItem("rate") && localStorage.getItem("rateTimeStamp") && isExist) {
                    if (localStorage.getItem("rateTimeStamp") && requestTimestampUtc <= +localStorage.getItem("rateTimeStamp") + 300000) {
                        _this.context.$store.commit("setRate", JSON.parse(localStorage.getItem("rate")));
                    } else if (localStorage.getItem("rateTimeStamp") && requestTimestampUtc > +localStorage.getItem("rateTimeStamp") + 300000) {
                        currentRate = await _this.getLatestTokenToUsdRate(); //TODO add support for other tokens
                        const newRates = {
                            ...currentRate,
                            ...savedTokensRate,
                        };
                        localStorage.setItem("rate", JSON.stringify(newRates));
                        localStorage.setItem("rateTimeStamp", requestTimestampUtc);
                        _this.context.$store.commit("setRate", newRates);
                    }
                } else {
                    currentRate = await _this.getLatestTokenToUsdRate();
                    const newRates = {
                        ...currentRate,
                        ...savedTokensRate,
                    };
                    localStorage.setItem("rate", JSON.stringify(newRates));
                    localStorage.setItem("rateTimeStamp", requestTimestampUtc);
                    _this.context.$store.commit("setRate", newRates);
                }
                setTimeout(tick, period);
            } catch (ex) {
                console.log(ex);
                setTimeout(tick, period);
            }
        }, 300);
    }

    updateUserInfo(userAddress, period = 3000) {
        let _this = this;

        setTimeout(async function tick() {
            try {
                let stakeTokens = _this.context.$store.getters.getStakeTokens;
                let tokensTotalStake = _this.context.$store.getters.getTokensTotalStake;
                let allContractsData = _this.context.$store.state.allContractsData;
                let hasReferrerObj = {};
                if (Object.keys(tokensTotalStake).length === 0) {
                    throw Error("no contract Data");
                }

                //TODO add alternative check
                // if(!farmTokens.length){
                //     throw new Error
                // }

                //getting raw stake data from all contracts
                //   let {V1StakeDataRaw, V2StakeDataRaw, V3StakeDataRaw} =  await _this.getStakingDetails(userAddress);

                const data = await _this.getStakingDetails(userAddress);

                const allStakeEventsFromAllChains = await _this.getStakingDetailsFromAllContracts(userAddress);

                const referrals = await _this.getReferrals(userAddress);
                _this.context.$store.commit("setReferralsNumber", referrals.length);
                const otherChainStakes = [];
                for (let [identifier, data] of Object.entries(allStakeEventsFromAllChains)) {
                    //excluding events in the same chain id or if array of events is 0
                    const blockchain = identifier.substring(0, identifier.indexOf("_"));
                    if (Number(_this.currentBlockchain) === Number(blockchain) || !data.length) {
                        continue;
                    }

                    const chainId = identifier.substring(0, identifier.indexOf("_"));
                    const contractAddress = identifier.substring(identifier.indexOf("_") + 1, identifier.length);
                    const contractId = Object.keys(conf[chainId].CONTRACT_VERSION_CONFIG).find(
                        (key) => conf[chainId].CONTRACT_VERSION_CONFIG[key].POOL_ADDRESS.toLowerCase() === contractAddress.toLowerCase()
                    );

                    const stakeData = data.map((el) => {
                        let stakeTokenAddress = el.staked_token_address;

                        let farmTokensList = conf[chainId].stakeTokens.find(
                            (stakeToken) => stakeToken.address.toLowerCase() === stakeTokenAddress.toLowerCase() && stakeToken.contractId === contractId
                        )?.farmTokensList;

                        let stakeTokenData = conf[chainId].stakeTokens.find(
                            (stakeToken) => stakeTokenAddress.toLowerCase() === stakeToken.address.toLowerCase() && stakeToken.contractId === contractId
                        );

                        const maxTokensStake = allContractsData.find((el) => el.contract_identifier.toLowerCase() === identifier.toLowerCase()).data_obj
                            .totalStaked[stakeTokenAddress.toLowerCase()];

                        return {
                            ...el.data_obj,
                            contract_identifier: identifier,
                            contractAddress: contractAddress,
                            chainId: chainId,
                            blockchainSymbol: Number(chainId) === 56 ? "BSC" : Number(chainId) === 1 ? "ETH" : "HARM",
                            name: stakeTokenData.name,
                            isActive: true,
                            maxStake: stakeTokenData.totalMaxStake,
                            coefTokens: stakeTokenData.coefTokens,
                            maxProfitPercent: stakeTokenData.maxProfitPercent,
                            userMaxStake: stakeTokenData.userMaxStake,
                            decimals: stakeTokenData.decimals,
                            farmTokensList,
                            contractId,
                            tag: stakeTokenData.tag,
                            contractType: stakeTokenData.contractType,
                            farmTokensReward: new Array(farmTokensList.length).fill(0, 0, farmTokensList.length),
                            maxTokensStake: maxTokensStake,
                        };
                    });
                    otherChainStakes.push(...stakeData);
                }

                let refNumber = 0;
                let stakingData = [];
                for (let [index, address] of _this.contractAddresses.entries()) {
                    let contractId = `N${index + 1}`;

                    const version = _this.Config.CONTRACTS.find((el) => el.ADDRESS.toLowerCase() === address).VERSION;
                    const stakeData = data[`${contractId}StakeDataRaw`].map((el) => {
                        let stakeTokenAddress = el.staked_token_address;

                        let farmTokensList = stakeTokens.find(
                            (stakeToken) => stakeToken.address.toLowerCase() === stakeTokenAddress.toLowerCase() && stakeToken.contractId === contractId
                        )?.farmTokensList;

                        let stakeTokenData = _this.Config.stakeTokens.find(
                            (stakeToken) => stakeTokenAddress.toLowerCase() === stakeToken.address.toLowerCase() && stakeToken.contractId === contractId
                        );

                        return {
                            ...el.data_obj,
                            name: stakeTokenData.name,
                            isActive: true,
                            maxStake: stakeTokenData.totalMaxStake,
                            coefTokens: stakeTokenData.coefTokens,
                            maxProfitPercent: stakeTokenData.maxProfitPercent,
                            userMaxStake: stakeTokenData.userMaxStake,
                            decimals: stakeTokenData.decimals,
                            farmTokensList,
                            poolVersion: version,
                            contractId,
                            tag: stakeTokenData.tag,
                            contractType: stakeTokenData.contractType,
                        };
                    });
                    const hasReferrer = await _this.hasReferrer(userAddress, contractId);
                    let stakeDataWithReward;

                    const updateTime = _this.contractAddresses.length - 1 === index ? true : false;

                    if (version === "V3") {
                        stakeDataWithReward = await _this.calcRewardForStakeTokens(
                            stakeData,
                            tokensTotalStake[contractId],
                            version,
                            contractId,
                            userAddress,
                            hasReferrer,
                            updateTime
                        );
                    }

                    stakingData.push(...stakeDataWithReward);
                    hasReferrerObj[`${contractId}`] = hasReferrer;
                    // refNumber += await _this.getReferrals(userAddress, version, contractId);
                }

                stakingData.sort((a, b) => b.block_timestamp - a.block_timestamp);
                stakingData.push(...otherChainStakes);

                const balance = Number((await _this.provider.getBalance(userAddress)) / 10 ** 18);

                if (!stakingData.length) {
                    _this.context.$store.commit("setStakesInfo", stakingData);
                } else if (stakingData.length && stakingData[0].farmTokensReward) {
                    _this.context.$store.commit("setStakesInfo", stakingData);
                } else {
                }
                _this.context.$store.commit("setReferrerObj", hasReferrerObj);
                _this.context.$store.commit("setUserCoinBalance", balance);

                setTimeout(tick, period);
            } catch (ex) {
                console.log(ex);
                setTimeout(tick, 300);
            }
        }, 300);
    }

    fetchUserStakesFromAllNetworks(userAddress, period = 3000) {
        let _this = this;

        setTimeout(async function tick() {
            try {
                let allContractsData = _this.context.$store.state.allContractsData;

                if (Object.keys(allContractsData).length === 0) {
                    throw Error("no contract Data");
                }

                const allStakeEventsFromAllChains = await _this.getStakingDetailsFromAllContracts(userAddress);

                const otherChainStakes = [];
                for (let [identifier, data] of Object.entries(allStakeEventsFromAllChains)) {
                    //excluding events in the same chain id or if array of events is 0
                    const blockchain = identifier.substring(0, identifier.indexOf("_"));
                    if (Number(_this.currentBlockchain) === Number(blockchain) || !data.length) {
                        continue;
                    }

                    const chainId = identifier.substring(0, identifier.indexOf("_"));
                    const contractAddress = identifier.substring(identifier.indexOf("_") + 1, identifier.length);
                    const contractId = Object.keys(conf[chainId].CONTRACT_VERSION_CONFIG).find(
                        (key) => conf[chainId].CONTRACT_VERSION_CONFIG[key].POOL_ADDRESS.toLowerCase() === contractAddress.toLowerCase()
                    );

                    const stakeData = data.map((el) => {
                        let stakeTokenAddress = el.staked_token_address;

                        let farmTokensList = conf[chainId].stakeTokens.find(
                            (stakeToken) => stakeToken.address.toLowerCase() === stakeTokenAddress.toLowerCase() && stakeToken.contractId === contractId
                        )?.farmTokensList;

                        let stakeTokenData = conf[chainId].stakeTokens.find(
                            (stakeToken) => stakeTokenAddress.toLowerCase() === stakeToken.address.toLowerCase() && stakeToken.contractId === contractId
                        );

                        const maxTokensStake = allContractsData.find((el) => el.contract_identifier.toLowerCase() === identifier.toLowerCase()).data_obj
                            .totalStaked[stakeTokenAddress.toLowerCase()];

                        return {
                            ...el.data_obj,
                            contract_identifier: identifier,
                            contractAddress: contractAddress,
                            chainId: chainId,
                            blockchainSymbol: Number(chainId) === 56 ? "BSC" : Number(chainId) === 1 ? "ETH" : "HARM",
                            name: stakeTokenData.name,
                            isActive: true,
                            maxStake: stakeTokenData.totalMaxStake,
                            coefTokens: stakeTokenData.coefTokens,
                            maxProfitPercent: stakeTokenData.maxProfitPercent,
                            userMaxStake: stakeTokenData.userMaxStake,
                            decimals: stakeTokenData.decimals,
                            farmTokensList,
                            contractId,
                            tag: stakeTokenData.tag,
                            contractType: stakeTokenData.contractType,
                            farmTokensReward: new Array(farmTokensList.length).fill(0, 0, farmTokensList.length),
                            maxTokensStake: maxTokensStake,
                        };
                    });
                    otherChainStakes.push(...stakeData);
                }

                otherChainStakes.sort((a, b) => b.block_timestamp - a.block_timestamp);

                if (!otherChainStakes.length) {
                    _this.context.$store.commit("setStakesInfo", otherChainStakes);
                } else if (otherChainStakes.length && otherChainStakes[0].farmTokensReward) {
                    _this.context.$store.commit("setStakesInfo", otherChainStakes);
                }

                setTimeout(tick, period);
            } catch (ex) {
                console.log(ex);
                setTimeout(tick, 300);
            }
        }, 300);
    }

    async getReferralsFromContracts(userAddress) {
        let refNumber = 0;
        for (let [index, address] of this.contractAddresses.entries()) {
            let contractId = `N${index + 1}`;
            const version = this.Config.CONTRACTS.find((el) => el.ADDRESS.toLowerCase() === address).VERSION;
            refNumber += await this.getReferrals(userAddress, version, contractId);
        }
        this.context.$store.commit("setReferralsNumber", refNumber);
    }

    // async getFarmingTokens() {
    //     let stakeTokensAddresses = this.Config.stakeTokens;
    //     let farmTokensAddresses = []

    //     // for (let i = 0; i < stakeTokensAddresses.length; i++){
    //     //     let address = await this.farm.getPoolTokens(stakeTokensAddresses[i].address);
    //     //     farmTokensAddresses.push(address)
    //     // }

    //     let allTokens = (stakeTokensAddresses.map((token, index) => {
    //         let farmTokensData = farmTokensAddresses[index].map(el => {
    //             let farmTokenData = this.Config.stakeTokens.find(farmToken =>  farmToken.address === el).farmTokens;
    //             return farmTokenData
    //             })

    //         return {
    //             tokenName: token.name,
    //             tag: token.tag,
    //             tokenAddress: token.address,
    //             tokenDecimals: token.decimal,
    //             userMinStake: token.userMinStake,
    //             userMaxStake: token.userMaxStake,
    //             totalMaxStake: token.totalMaxStake,
    //             // lockableDays: token.lockableDays,
    //             // optionableStatus: token.optionableStatus,
    //             farmTokensData: farmTokensData,
    //         }

    //     }))

    //     return allTokens
    // }

    async calcRewardForStakeTokens(stakingData, totalStakes, contractVersion, contractId, userAddress, hasReferrer, updateTime) {
        //TODO fix wrong reward saved to ls
        if ((contractVersion = "V3")) {
            let currentTime = Math.floor(new Date().getTime() / 1000);
            let previousStoreTime = Number(window.localStorage.getItem(`${this.currentBlockchain}_stakeInfoTime`)) || 0;
            let stakeArrayToLocalStore = [];
            const perviousStakesInfo = JSON.parse(window.localStorage.getItem(`${this.currentBlockchain}_stakesAddr:${userAddress}`)) || {};
            const allSavedStakes = Object.values(perviousStakesInfo).flat();

            // const hasSomething = Boolean(Object.keys(perviousStakesInfo).length);

            for (let i = 0; i < stakingData.length; i++) {
                //stake data
                const stakeId = stakingData[i].stakeIdx;
                const tokenAddress = stakingData[i].stakedTokenAddress.toLowerCase();
                const decimals = stakingData[i].decimals;
                const totalStake = totalStakes[tokenAddress.toLowerCase()];
                const startTime = Number(stakingData[i].block_timestamp);
                let afterPenalty = this.isAfterPenalty(startTime, contractVersion, contractId);

                //rewards taken from local store
                if (
                    stakingData[i].isActive === true &&
                    currentTime < previousStoreTime + 60 &&
                    // hasSomething &&
                    perviousStakesInfo[contractId]?.length > 0 &&
                    (!this.context.$store.state.stakesInfo || perviousStakesInfo[contractId]?.length === this.context.$store.state.stakesInfo?.length)
                ) {
                    const rewardsArray =
                        perviousStakesInfo[contractId].find(
                            (stake) => stake.stakeId === stakeId && stake.tokenAddress === tokenAddress && stake.userAddress === userAddress
                        )?.farmTokensReward || [];
                    const possibleRewardsArray = perviousStakesInfo[contractId].find(
                        (stake) => stake.stakeId === stakeId && stake.tokenAddress === tokenAddress && stake.userAddress === userAddress
                    )?.farmTokensPossibleReward || [0, 0, 0, 0, 0];

                    stakingData[i]["farmTokensReward"] = rewardsArray;
                    stakingData[i]["farmTokensPossibleReward"] = possibleRewardsArray;
                    stakingData[i]["maxTokensStake"] = totalStake;
                } else if (
                    stakingData[i].isActive === true &&
                    (perviousStakesInfo[contractId]?.length === this.context.$store.state.stakesInfo?.length ||
                        perviousStakesInfo[contractId]?.length !== this.context.$store.state.stakesInfo?.length ||
                        !this.context.$store.state.stakesInfo) &&
                    currentTime >= previousStoreTime + 60
                ) {
                    //rewards taken from blockchian and stored to localstore

                    const rewardsArray = [];
                    const possibleRewardsArray = [];
                    // const userMultiplier = this.getUserMultiplier(startTime, poolVersion)

                    const rawResult = await this[contractId].getStakeRewards(userAddress, stakeId, afterPenalty);

                    for (let k = 0; k < stakingData[i].farmTokensList.length; k++) {
                        //calculating rewards earned from stake and max possible reward for stake for each token
                        const reward = Number(rawResult[k]["_hex"]) / 10 ** decimals;
                        // const maxPossibleReward = Number(this.calcReward(stakeAmount, poolVersion, startTime, totalStake, stakingData[i].farmTokensList[k].rewardRatePerBlock, "stake"))
                        const maxPossibleReward = 0; // TODO: calc maxPossibleReward for verion3

                        if (!hasReferrer) {
                            reward *= 0.9;
                            maxPossibleReward *= 0.9;
                        } else {
                            reward *= 0.94;
                            maxPossibleReward *= 0.94;
                        }
                        rewardsArray[k] = reward;
                        possibleRewardsArray[k] = maxPossibleReward;
                    }

                    const saveStakeObject = {
                        userAddress,
                        stakeId: stakeId,
                        tokenAddress: tokenAddress,
                        farmTokensReward: rewardsArray,
                        farmTokensPossibleReward: possibleRewardsArray,
                    };
                    stakeArrayToLocalStore.push(saveStakeObject);
                    stakingData[i]["farmTokensReward"] = rewardsArray;
                    stakingData[i]["farmTokensPossibleReward"] = possibleRewardsArray;
                    stakingData[i]["maxTokensStake"] = totalStake;
                } else if (allSavedStakes.length > 0 && this.context.$store.state.stakesInfo?.length > allSavedStakes?.length) {
                    //rewards taken from blockchian and stored to localstore

                    const rewardsArray = [];
                    const possibleRewardsArray = [];
                    // const userMultiplier = this.getUserMultiplier(startTime, poolVersion)

                    const rawResult = await this[contractId].getStakeRewards(userAddress, stakeId, afterPenalty);

                    for (let k = 0; k < stakingData[i].farmTokensList.length; k++) {
                        //calculating rewards earned from stake and max possible reward for stake for each token
                        const reward = Number(rawResult[k]["_hex"]) / 10 ** decimals;
                        // const maxPossibleReward = Number(this.calcReward(stakeAmount, poolVersion, startTime, totalStake, stakingData[i].farmTokensList[k].rewardRatePerBlock, "stake"))
                        const maxPossibleReward = 0; // TODO: calc maxPossibleReward for verion3

                        if (!hasReferrer) {
                            reward *= 0.9;
                            maxPossibleReward *= 0.9;
                        } else {
                            reward *= 0.94;
                            maxPossibleReward *= 0.94;
                        }
                        rewardsArray[k] = reward;
                        possibleRewardsArray[k] = maxPossibleReward;
                    }

                    const saveStakeObject = {
                        userAddress,
                        stakeId: stakeId,
                        tokenAddress: tokenAddress,
                        farmTokensReward: rewardsArray,
                        farmTokensPossibleReward: possibleRewardsArray,
                    };
                    stakeArrayToLocalStore.push(saveStakeObject);
                    stakingData[i]["farmTokensReward"] = rewardsArray;
                    stakingData[i]["farmTokensPossibleReward"] = possibleRewardsArray;
                    stakingData[i]["maxTokensStake"] = totalStake;
                }
                continue;
            }

            if (currentTime > previousStoreTime + 60 || this.context.$store.state.stakesInfo?.length > allSavedStakes?.length) {
                if (stakeArrayToLocalStore.length) {
                    const prevEvents = JSON.parse(window.localStorage.getItem(`${this.currentBlockchain}_stakesAddr:${userAddress}`)) || {};
                    prevEvents[contractId] = stakeArrayToLocalStore;
                    window.localStorage.setItem(`${this.currentBlockchain}_stakesAddr:${userAddress}`, JSON.stringify(prevEvents));
                }

                if (updateTime === true || previousStoreTime === 0) {
                    window.localStorage.setItem(`${this.currentBlockchain}_stakeInfoTime`, currentTime);
                }
            }

            return stakingData;
        }
    }

    async subscribeToEvents() {
        try {
            if (!this.providerInstance) {
                return;
            }

            this.providerInstance.on("accountsChanged", async (accounts) => {
                let currentAccount = window.localStorage.getItem("address");
                // console.log(`connector.on("accountsChanged")`);

                const address = accounts[0];

                if (!currentAccount || address.toLowerCase() !== currentAccount.toLowerCase()) {
                    currentAccount = address;
                    localStorage.setItem("address", currentAccount);

                    // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                    location.reload();
                } else if (address.toLowerCase() === currentAccount.toLowerCase()) {
                    this.context.$store.commit("setCurrentAddress", address);
                }
            });
            this.providerInstance.on("chainChanged", async (_chainId) => {
                const currentNetwork = window.localStorage.getItem("selectedNetwork");
                // console.log(`connector.on("accountsChanged")`);

                const network = Number(_chainId);
                window.localStorage.setItem("selectedNetwork", network);
                window.localStorage.removeItem(`${network}_stakeInfoTime`);
                // window.localStorage.removeItem(`${network}_stakesAddr:${_this.currentUserAddress}`);

                window.location.reload();
            });

            this.providerInstance.on("connect", (error, payload) => {
                // console.log(`connector.on("connect")`);

                if (error) {
                    throw error;
                }

                this.onConnect(payload);
            });

            this.providerInstance.on("disconnect", (code, reason) => {
                // console.log(`connector.on("disconnect")`, code, reason);

                this.onDisconnect();
            });

            if (this.providerInstance.connected) {
                const { chainId, accounts } = this.providerInstance;

                this.onSessionUpdate(accounts, chainId);
            }
        } catch (error) {
            console.log(error);
        }
    }
    async killSession() {
        await this.providerInstance.disconnect();
    }

    async onDisconnect() {
        window.localStorage.removeItem("address");
        window.localStorage.removeItem("selectedNetwork");
        window.localStorage.removeItem("walletconnect");
        window.location.reload();
    }

    async onSessionUpdate(accounts, chainId) {
        let currentAccount = localStorage.getItem("address");
        const address = accounts[0];
        if (!currentAccount || address.toLowerCase() !== currentAccount.toLowerCase()) {
            currentAccount = address;
            localStorage.setItem("address", currentAccount);
            window.localStorage.setItem("selectedNetwork", Number(chainId));

            // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
            location.reload();
        } else if (address.toLowerCase() === currentAccount.toLowerCase()) {
            this.currentBlockchain = Number(chainId);
            this.context.$store.commit("setCurrentBlockchain", this.currentBlockchain);
            this.context.$store.commit("setCurrentAddress", address);
        }
    }

    // toggleModal = () => this.setState({ showModal: !this.state.showModal });

    async getLatestTokenToUsdRate() {
        // const tokens = ["0x3ecb96039340630c8b82e5a7732bc88b2aeade82"]; //TODO take from config
        let stakeTokens = this.Config.stakeTokens;
        let farmTokens = this.Config.farmTokens;
        // for (let chain of conf.SUPPORTED_BLOCKCHAINS) {
        //     tokens = [...tokens, ...conf[chain].stakeTokens];
        // }

        let tokens = [...stakeTokens, ...farmTokens];

        const result = {};
        for (let token of tokens) {
            let isLpToken = token.name.includes("-lp");
            if (isLpToken) {
                const coinPriceLink = `https://api.coingecko.com/api/v3/simple/price?ids=${token.baseCurrency}&vs_currencies=USD`;
                let res = await axios.get(coinPriceLink);
                const coinPrice = res.data[`${token.baseCurrency}`].usd;
                const pairContract = new ethers.Contract(token.address, lpToken, this.provider).connect(this.provider);
                const reserves = await pairContract.getReserves();
                let totalSupply = await pairContract.totalSupply();
                //TODO change if token worths more than coin
                let coinReserve = Math.min(Number(ethers.utils.formatUnits(reserves[1]._hex)), Number(ethers.utils.formatUnits(reserves[0]._hex)));
                // let tokenReserve = Math.max(Number(ethers.utils.formatUnits(reserves[1]._hex)), Number(ethers.utils.formatUnits(reserves[0]._hex)));
                let flipTotalSupply = ethers.utils.formatUnits(totalSupply._hex);
                const flipTokenPrice = (coinReserve * coinPrice * 2) / Number(flipTotalSupply);
                result[`${token.address}`] = flipTokenPrice.toFixed(2);
            } else {
                let tokenAddress =
                    token.address.toLowerCase() === "0x939d5a13cf0074586a2dcf17bc692b2d3ccdd517".toLowerCase()
                        ? "0x3ecb96039340630c8b82e5a7732bc88b2aeade82".toLowerCase()
                        : token.address.toLowerCase();

                const requestLink = `https://api.coingecko.com/api/v3/simple/token_price/${token.priceFrom}?contract_addresses=${tokenAddress}&vs_currencies=usd`;
                let response = await axios.get(requestLink);
                result[`${token.address.toLowerCase()}`] = response.data[`${tokenAddress}`].usd;
            }
        }

        return result;
        return 1;
    }

    isAfterPenalty(startTime, contractVersion, contractId) {
        const POOL_START_TIME = this.Config.CONTRACT_VERSION_CONFIG[contractId].POOL_START_TIME;
        const POOL_END_TIME = this.Config.CONTRACT_VERSION_CONFIG[contractId].POOL_END_TIME;
        const STAKE_DAYS = this.Config.CONTRACT_VERSION_CONFIG[contractId].STAKE_DAYS;
        const PENTALTY_DURATION = this.Config.CONTRACT_VERSION_CONFIG[contractId].PENTALTY_DURATION;
        let currentTime = Math.floor(new Date().getTime() / 1000);
        return currentTime > startTime + PENTALTY_DURATION * 24 * 3600 ? true : false;
    }
    getUserMultiplier(stakeTime, contractVersion, contractId) {
        const POOL_START_TIME = this.Config.CONTRACT_VERSION_CONFIG[contractId].POOL_START_TIME;
        const POOL_END_TIME = this.Config.CONTRACT_VERSION_CONFIG[contractId].POOL_END_TIME;
        const STAKE_DAYS = this.Config.CONTRACT_VERSION_CONFIG[contractId].STAKE_DAYS;
        const MULTIPLIER = this.Config.CONTRACT_VERSION_CONFIG[contractId].MULTIPLIER;
        const daysPassedBeforeStake = ((stakeTime - POOL_START_TIME) / 3600 / 24).toFixed(2); //TODO returm Math.floor
        const stakingTime = POOL_END_TIME - (POOL_START_TIME + daysPassedBeforeStake * 24 * 3600);

        const res = 1 + ((MULTIPLIER - 1) * stakingTime) / (STAKE_DAYS * 3600); // TODO return *24

        return res;
    }
    async hasReferrer(userAddress, contractId) {
        //TODO add enpoint to API and use it
        const rawRes = await this[contractId].users(userAddress);
        return rawRes[1] !== "0x0000000000000000000000000000000000000000";
    }

    calcReward(stakeAmount, poolVersion, contractId, time, totalStake, rewardRatePerBlock, type) {
        if (type === "pool") {
            const reward =
                ((Number(stakeAmount) * (this.Config.CONTRACT_VERSION_CONFIG[contractId].POOL_END_TIME - time)) / (totalStake + Number(stakeAmount))) *
                rewardRatePerBlock;
            return reward;
        } else if (type === "stake") {
            const startTime = time;
            const reward =
                ((Number(stakeAmount) * (this.Config.CONTRACT_VERSION_CONFIG[contractId].POOL_END_TIME - startTime)) / totalStake) * rewardRatePerBlock;

            return reward;
        }
    }
    async getStakingDetails(userAddress) {
        // if (!this.currentBlockchain) {
        //     this.init();
        // }
        let resultRaw = await axios.get("/getAllActiveStakes", {
            baseURL: this.baseURL,
            params: {
                address: userAddress || "0x0",
                blockchain: this.currentBlockchain || conf.PRIMARY_BLOCKCHAIN.chainId,
                contractAddress: this.contractAddresses,
            },
        });

        let res = resultRaw.data;
        let resultObj = {};

        for (let [index, address] of this.contractAddresses.entries()) {
            resultObj[`N${index + 1}StakeDataRaw`] = res[address].sort((a, b) => b.block_timestamp - a.block_timestamp);
        }

        return resultObj;
    }
    async getStakingDetailsFromAllContracts(userAddress) {
        // if (!this.currentBlockchain) {
        //     this.init();
        // }
        const tables = [];

        for (let chain of conf.SUPPORTED_BLOCKCHAINS) {
            for (let pool of conf[chain].CONTRACTS) {
                tables.push(`${chain}_${pool.ADDRESS.toLowerCase()}`);
            }
        }

        let resultRaw = await axios.get("/getAllActiveStakesFromAllContracts", {
            baseURL: this.baseURL,
            params: {
                address: userAddress || "0x0",
                tables: tables,
            },
        });

        let res = resultRaw.data;
        let resultObj = {};

        return res;

        return resultObj;
    }
    async getReferrals(userAddress) {
        // if (!this.currentBlockchain) {
        //     this.init();
        // }
        let resultRaw = await axios.get("/getReferralsCount", {
            baseURL: this.baseURL,
            params: {
                address: userAddress || "0x0",
                blockchain: this.currentBlockchain || conf.PRIMARY_BLOCKCHAIN.chainId,
                contractAddress: this.contractAddresses,
            },
        });

        let res = resultRaw.data;

        return res;
    }
    async forceRestart(blockchain, address) {
        try {
            await axios.post(`${this.baseURL}/forceUpdate`, {
                blockchain: blockchain || conf.PRIMARY_BLOCKCHAIN.chainId,
                address: address || "0x0",
            });
        } catch (error) {
            console.log(error);
        }
    }
    async estimateGas(method, contractId, params) {
        if (method === "stake") {
            if (!params.ref || params.ref.length < 42) {
                params.ref = this.defaultReferrer;
            }

            let amount = ethers.utils.parseUnits(`${params.amount}`, params.decimals);
            return await this[`${contractId}`].estimateGas[method](params.ref, params.token, amount);
        } else if (method === "claimReward") {
            return await this[`${contractId}`].estimateGas[method](params.stakeId);
        } else if (method === "unstake") {
            return await this[`${contractId}`].estimateGas[method](params.stakeId);
        }
    }

    async getContractsData() {
        const response = await axios.get(`${this.baseURL}/getContractsData`);

        return response.data;
    }
    async changeNetwork(context, blockchain) {
        const selectedWallet = window.localStorage.getItem("selectedWallet");
        let _this = context;
        const networkObject = conf.NETWORK_PARAMS.find((el) => el.symbol === blockchain);

        const params = [
            {
                chainId: networkObject.params.chainId,
                chainName: networkObject.params.chainName,
                nativeCurrency: networkObject.params.nativeCurrency,
                rpcUrls: networkObject.params.rpcUrls,
                blockExplorerUrls: networkObject.params.blockExplorerUrls,
            },
        ];
        const switchParams = [{ chainId: networkObject.params.chainId }];

        if (selectedWallet && selectedWallet === "metamask") {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: switchParams,
                    });
                } catch (switchError) {
                    // This error code indicates that the chain has not been added to MetaMask.
                    if (switchError.code === 4902 || switchError?.code?.toString() === "-32603") {
                        try {
                            await window.ethereum.request({
                                method: "wallet_addEthereumChain",
                                params: params,
                            });
                        } catch (addError) {
                            console.log(addError);
                        }
                    }

                    // handle other "switch" errors
                }
            } else {
                alert("Please install metamask wallet extension");
            }
        } else if (selectedWallet && selectedWallet === "walletconnect") {
            try {
                await this.provider.provider.request({
                    method: "wallet_switchEthereumChain",
                    params: switchParams,
                });
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902 || switchError?.code?.toString() === "-32603" || switchError.toString().includes("Unrecognized chain")) {
                    try {
                        await this.provider.provider.request({
                            method: "wallet_addEthereumChain",
                            params: params,
                        });
                    } catch (addError) {
                        console.log(addError);
                    }
                }
                console.log(switchError);

                // handle other "switch" errors
            }
        } else if (selectedWallet && selectedWallet === "onto") {
            try {
                await window.onto.request({
                    method: "wallet_switchEthereumChain",
                    params: switchParams,
                });
            } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902 || switchError?.code?.toString() === "-32603" || switchError.toString().includes("Unrecognized chain")) {
                    try {
                        await this.provider.provider.request({
                            method: "wallet_addEthereumChain",
                            params: params,
                        });
                    } catch (addError) {
                        console.log(addError);
                    }
                }
                console.log(switchError);
                if (switchError.message === "the method wallet_switchEthereumChain does not exist/is not available") {
                    this.context.$store.commit("setShowOntoTutorial", true);
                    return;
                }

                // handle other "switch" errors
            }
        }
    }

    async getStakingDetailsFromContract(userAddress, contractVersion) {
        let tokensTotalStake = this.context.$store.getters.getTokensTotalStake;
        let totalStakes = tokensTotalStake[contractVersion];
        const resultRaw = await this[contractVersion].viewStakingDetails(userAddress, "0", "0");
        let result = [];

        function getCol(array, col) {
            var column = [];
            for (var i = 0; i < array.length; i++) {
                column.push(array[i][col]);
            }
            return column;
        }

        for (let i = 0; i < resultRaw[0].length; i++) {
            let column = getCol(resultRaw, i);
            let decimals = this.Config.stakeTokens.find((el) => el.address.toLocaleLowerCase() === column[1].toLocaleLowerCase()).decimals;
            let farmTokensList = this.Config.stakeTokens.find(
                (stakeToken) => stakeToken.address.toLowerCase() === column[1].toLowerCase() && stakeToken.contractId === contractVersion
            ).farmTokensList;

            let stakeTokenData = this.Config.stakeTokens.find(
                (stakeToken) => column[1].toLowerCase() === stakeToken.address.toLowerCase() && stakeToken.contractId === contractVersion
            );

            let stakeObj = {
                stakedTokenAddress: column[1],
                isActive: Number(column[4]) > 0 ? false : true,
                stakeId: Number.parseInt(column[0]),
                tokenAmount: Number(column[2]) / 10 ** decimals,
                block_timestamp: Number.parseInt(column[3]),
                unstakeTime: Number(parseInt(column[4])),
                contractId: contractVersion,
                maxTokensStake: totalStakes[column[1].toLowerCase()],
                userAddress,
                farmTokensList,
                farmTokensReward: new Array(farmTokensList.length).fill(0, 0, farmTokensList.length),

                name: stakeTokenData.name,
                maxStake: stakeTokenData.totalMaxStake,
                coefTokens: stakeTokenData.coefTokens,
                maxProfitPercent: stakeTokenData.maxProfitPercent,
                userMaxStake: stakeTokenData.userMaxStake,
                decimals: stakeTokenData.decimals,
                contractType: stakeTokenData.contractType,
                tag: stakeTokenData.tag,
            };

            result.push(stakeObj);
        }
        result.sort((a, b) => b.startTime - a.startTime);

        return result;
    }

    async getUserTotalStaking(userAddress, tokenAddress, contractVersion) {
        let resultRaw = await this[contractVersion].userTotalStaking(userAddress, tokenAddress);
    }
    async fetchLastStakes(userAddress, contractId) {
        const result = await this.getStakingDetailsFromContract(userAddress, contractId);

        this.context.$store.commit("setLastStakesFromContract", result);
    }

    async stake(tokenAddress, amount, decimals, ref = "", contractVersion, contractId) {
        if (!ref) {
            ref = this.defaultReferrer;
        }

        amount = ethers.utils.parseUnits(`${amount}`, decimals);
        let rawTransactions = await this[contractId].stake(ref, tokenAddress, amount, {
            gasPrice: ethers.utils.parseUnits(this.Config.DEFAULT_GAS_PRICE_GWEI, "gwei"),
        });

        return rawTransactions;
    }

    async unstake(userAddress, stakeId, contractId) {
        let rawTransactions = await this[contractId].unstake(stakeId, {
            gasPrice: ethers.utils.parseUnits(this.Config.DEFAULT_GAS_PRICE_GWEI, "gwei"),
        });

        return rawTransactions;
    }

    async claimReward(stakeId, contractId) {
        let rawTransactions = await this[contractId].claimReward(stakeId);

        return rawTransactions;
    }

    async approve(tokenContract, amount, decimals, contractVersion, contractId) {
        amount = ethers.utils.parseUnits(`${amount}`, decimals);

        const rawTransaction = await tokenContract.approve(this[contractId].address, amount);
        return rawTransaction;
    }

    async totalTokensCount(contractVersion) {
        const result = await this[contractVersion].totalTokensCount();
        return result;
    }

    async getPoolStartTime(contractVersion) {
        const resultRaw = await this[contractVersion].poolStartTime();
        return Number.parseInt(resultRaw._hex);
    }

    async getInfoForRewardPage(userAddress) {
        // if (!this.currentBlockchain) {
        //     this.init();
        // }
        const response = await axios.get(`${this.baseURL}/getRewardPageInfoForUser`, {
            params: {
                contractAddress: this.contractAddresses,
                blockchain: this.currentBlockchain || conf.PRIMARY_BLOCKCHAIN.chainId,
                address: userAddress || "0x0",
            },
        });
        let resultObject = {};
        let resultArray = [];
        for (let res of response.data) {
            for (let [contractAddress, allEvents] of Object.entries(res)) {
                const poolVersion = Object.entries(this.Config.CONTRACTS).find((el) => el[1].ADDRESS.toLowerCase() === contractAddress.toLowerCase())[1]
                    .VERSION;
                const contractId = Object.entries(this.Config.CONTRACT_VERSION_CONFIG).find(
                    (el) => el[1].POOL_ADDRESS.toLowerCase() === contractAddress.toLowerCase()
                )[0];

                resultObject[contractAddress] = {};
                for (let [stakeIndex, stakeEvents] of Object.entries(allEvents)) {
                    resultObject[contractAddress][stakeIndex] = {};
                    for (let event of stakeEvents) {
                        if (event.eventNameUniversal === "Stake") {
                            resultObject[contractAddress][stakeIndex]["stakeIndex"] = stakeIndex;
                            resultObject[contractAddress][stakeIndex]["poolVersion"] = poolVersion;
                            resultObject[contractAddress][stakeIndex]["contractId"] = contractId;
                            resultObject[contractAddress][stakeIndex]["stakeStartTime"] = event.block_timestamp;
                            resultObject[contractAddress][stakeIndex]["stakeAmount"] = event.tokenAmount;
                            resultObject[contractAddress][stakeIndex]["stakedTokenAddress"] = event.stakedTokenAddress;
                            resultObject[contractAddress][stakeIndex]["isActive"] = true;
                        } else if (event.eventNameUniversal === "Unstake") {
                            const contractType = Object.entries(this.Config.stakeTokens).find(
                                (el) => el[1].address.toLowerCase() === event.stakedTokenAddress.toLowerCase()
                            )[1].contractType;

                            resultObject[contractAddress][stakeIndex]["unstakeTime"] = event.block_timestamp;
                            resultObject[contractAddress][stakeIndex]["unstakeAmount"] = event.tokenAmount;
                            resultObject[contractAddress][stakeIndex]["stakedTokenAddress"] = event.stakedTokenAddress;
                            resultObject[contractAddress][stakeIndex]["transaction_id"] = event.transaction_id;
                            resultObject[contractAddress][stakeIndex]["isActive"] = false;
                            resultObject[contractAddress][stakeIndex]["contractType"] = contractType;
                        } else if (event.eventNameUniversal === "Claim") {
                            resultObject[contractAddress][stakeIndex]["claimTime"] = event.block_timestamp;
                            resultObject[contractAddress][stakeIndex]["isActive"] = true;
                            resultObject[contractAddress][stakeIndex]["claimEvents"] = [
                                ...(resultObject[contractAddress][stakeIndex]["claimEvents"] || []),
                                event,
                            ];
                        } else if (event.eventNameUniversal === "ReferralEarn") {
                            resultObject[contractAddress][stakeIndex]["referralTime"] = event.block_timestamp;
                            resultObject[contractAddress][stakeIndex]["referralEvents"] = [
                                ...(resultObject[contractAddress][stakeIndex]["referralEvents"] || []),
                                event,
                            ];
                        } else if (event.eventNameUniversal === "ReferralPayout") {
                            resultObject[contractAddress][stakeIndex]["referralTime"] = event.block_timestamp;
                            resultObject[contractAddress][stakeIndex]["referralEvents"] = [
                                ...(resultObject[contractAddress][stakeIndex]["referralEvents"] || []),
                                event,
                            ];
                        } else if (event.eventNameUniversal === "RewardPayout") {
                            //todo add logic to analyze isActive status
                            resultObject[contractAddress][stakeIndex]["rewardPayoutTime"] = event.block_timestamp;

                            resultObject[contractAddress][stakeIndex]["rewardPayoutEvents"] = [
                                ...(resultObject[contractAddress][stakeIndex]["rewardPayoutEvents"] || []),
                                event,
                            ];
                        }
                    }
                }
            }
        }
        // resultArray.push(resultObject)
        // return resultArray;

        return resultObject;
    }

    async getTotalStaking() {
        // if (!this.currentBlockchain) {
        //     this.init();
        // }

        const totalStakeDataRaw = await axios.get("/getContractTotalStakedForAllPools", {
            baseURL: this.baseURL,
            params: {
                contractAddress: this.contractAddresses,
                blockchain: this.currentBlockchain || conf.PRIMARY_BLOCKCHAIN.chainId,
            },
        });

        const res = totalStakeDataRaw.data;

        let resultObj = {};

        for (let [index, address] of this.contractAddresses.entries()) {
            resultObj[`N${index + 1}TotalStake`] = res[address]["totalStaked"];
            resultObj[`N${index + 1}PenaltyInfo`] = res[address]["poolPenaltyInfo"];
        }

        return resultObj;
    }

    // async getLatestInfoFromContract(userAddress) {//TODO: Not used?
    //     let _this = this;
    //     try {
    //         let stakeTokens = _this.context.$store.getters.getStakeTokens;
    //         let tokensTotalStake =
    //             _this.context.$store.getters.getTokensTotalStake;

    //         //getting raw stake data from all contracts
    //         let V1StakingDataRaw = await _this.getStakingDetailsFromContract(
    //             userAddress,
    //             "V1"
    //         );
    //         let V2StakingDataRaw = await _this.getStakingDetailsFromContract(
    //             userAddress,
    //             "V2"
    //         );
    //         let V3StakingDataRaw = await _this.getStakingDetailsFromContract(
    //             userAddress,
    //             "V3"
    //         );

    //         ///getting  stake rewards from all contracts

    //         for (let i = 0; i < V1StakingDataRaw.length; i++) {
    //             if (!V1StakingDataRaw[i].isActive) {
    //                 let stakeAmount = await _this.getStakeAmount(
    //                     userAddress,
    //                     V1StakingDataRaw[i].stakeId,
    //                     "V1",
    //                     V1StakingDataRaw[i].decimals
    //                 );
    //                 V1StakingDataRaw[i].stakeAmount = stakeAmount;
    //             }
    //         }

    //         for (let i = 0; i < V2StakingDataRaw.length; i++) {
    //             if (!V2StakingDataRaw[i].isActive) {
    //                 let stakeAmount = await _this.getStakeAmount(
    //                     userAddress,
    //                     V2StakingDataRaw[i].stakeId,
    //                     "V2",
    //                     V2StakingDataRaw[i].decimals
    //                 );
    //                 V2StakingDataRaw[i].stakeAmount = stakeAmount;
    //             }
    //         }

    //         for (let i = 0; i < V3StakingDataRaw.length; i++) {
    //             if (!V3StakingDataRaw[i].isActive) {
    //                 let stakeAmount = await _this.getStakeAmount(
    //                     userAddress,
    //                     V3StakingDataRaw[i].stakeId,
    //                     "V3",
    //                     V3StakingDataRaw[i].decimals
    //                 );
    //                 V3StakingDataRaw[i].stakeAmount = stakeAmount;
    //             }
    //         }

    //         //getting stake data for each pool contract

    //         let V1StakingData = V1StakingDataRaw.map((el) => {
    //             let stakeTokenAddress = el.tokenAddress;
    //             let farmTokensList = stakeTokens.find(
    //                 (stakeToken) =>
    //                     stakeToken.address.toLowerCase() ===
    //                         stakeTokenAddress.toLowerCase() &&
    //                     stakeToken.poolVersion === "V1"
    //             ).farmTokensList;

    //             let stakeTokenData = this.Config.stakeTokens.find(
    //                 (stakeToken) =>
    //                     stakeTokenAddress.toLowerCase() ===
    //                         stakeToken.address.toLowerCase() &&
    //                     stakeToken.poolVersion === "V1"
    //             );

    //             return {
    //                 ...el,
    //                 name: stakeTokenData.name,
    //                 maxStake: stakeTokenData.totalMaxStake,
    //                 coefTokens: stakeTokenData.coefTokens,
    //                 userMaxStake: stakeTokenData.userMaxStake,
    //                 decimals: stakeTokenData.decimals,
    //                 farmTokensList,
    //                 poolVersion: "V1",
    //             };
    //         });

    //         let V2StakingData = V2StakingDataRaw.map((el) => {
    //             let stakeTokenAddress = el.tokenAddress;
    //             let farmTokensList = stakeTokens.find(
    //                 (stakeToken) =>
    //                     stakeToken.address.toLowerCase() ===
    //                         stakeTokenAddress.toLowerCase() &&
    //                     stakeToken.poolVersion === "V2"
    //             ).farmTokensList;

    //             let stakeTokenData = this.Config.stakeTokens.find(
    //                 (stakeToken) =>
    //                     stakeTokenAddress.toLowerCase() ===
    //                         stakeToken.address.toLowerCase() &&
    //                     stakeToken.poolVersion === "V2"
    //             );

    //             return {
    //                 ...el,
    //                 name: stakeTokenData.name,
    //                 maxStake: stakeTokenData.totalMaxStake,
    //                 coefTokens: stakeTokenData.coefTokens,
    //                 userMaxStake: stakeTokenData.userMaxStake,
    //                 decimals: stakeTokenData.decimals,
    //                 farmTokensList,
    //                 poolVersion: "V2",
    //             };
    //         });

    //         let V3StakingData = V3StakingDataRaw.map((el) => {
    //             let stakeTokenAddress = el.tokenAddress;
    //             let farmTokensList = stakeTokens.find(
    //                 (stakeToken) =>
    //                     stakeToken.address.toLowerCase() ===
    //                         stakeTokenAddress.toLowerCase() &&
    //                     stakeToken.poolVersion === "V3"
    //             ).farmTokensList;

    //             let stakeTokenData = this.Config.stakeTokens.find(
    //                 (stakeToken) =>
    //                     stakeTokenAddress.toLowerCase() ===
    //                         stakeToken.address.toLowerCase() &&
    //                     stakeToken.poolVersion === "V3"
    //             );

    //             return {
    //                 ...el,
    //                 name: stakeTokenData.name,
    //                 maxStake: stakeTokenData.totalMaxStake,
    //                 coefTokens: stakeTokenData.coefTokens,
    //                 userMaxStake: stakeTokenData.userMaxStake,
    //                 decimals: stakeTokenData.decimals,
    //                 farmTokensList,
    //                 poolVersion: "V3",
    //             };
    //         });

    //         const hasReferrer = window.localStorage.getItem("ref") || "";
    //         let V1StakingDataWithReward = await _this.calcRewardForStakeTokens(
    //             V1StakingData,
    //             tokensTotalStake.N1,
    //             "N1"
    //         );
    //         let V2StakingDataWithReward = await _this.calcRewardForStakeTokens(
    //             V2StakingData,
    //             tokensTotalStake.N2,
    //             "N2"
    //         );
    //         let V3StakingDataWithReward = await _this.calcRewardForStakeTokens(
    //             V3StakingData,
    //             tokensTotalStake.N3,
    //             "N3",
    //             userAddress,
    //             hasReferrer
    //         );

    //         let stakingData = [
    //             ...V1StakingDataWithReward,
    //             ...V2StakingDataWithReward,
    //             ...V3StakingDataWithReward,
    //         ];

    //         stakingData.sort((a, b) => b.startTime - a.startTime);

    //         let refNumber = await _this.getReferrals(userAddress, "V1", "N1");
    //         refNumber += await _this.getReferrals(userAddress, "V2", "N2");
    //         refNumber += await _this.getReferrals(userAddress, "V3", "N3");
    //         _this.context.$store.commit("setReferralsNumber", refNumber);
    //         _this.context.$store.commit(
    //             "setStakesInfoFromContract",
    //             stakingData
    //         );
    //     } catch (ex) {
    //         console.log(ex);
    //     }
    // }

    async getTotalStakingOnSpecificContract(poolAddress, tokenContract) {
        if (this[tokenContract].maxTotalStaked) {
            const poolResultRaw = await this[tokenContract].maxTotalStaked(poolAddress);
            const totalStake = Number(ethers.utils.formatUnits(poolResultRaw._hex));

            return totalStake;
        } else if (this[tokenContract].totalStaking) {
            const poolResultRaw = await this[tokenContract].totalStaking(poolAddress);
            const totalStake = Number(ethers.utils.formatUnits(poolResultRaw._hex));

            return totalStake;
        } else if (this[tokenContract].pools) {
            const poolResultRaw = await this[tokenContract].pools(poolAddress);
            const totalStake = Number(ethers.utils.formatUnits(poolResultRaw.totalStaked));

            return totalStake;
        }
    }

    async checkReferrer(refAddress, contractVersion) {
        const result = await this[contractVersion].isActiveUser(refAddress);

        return result;
    }

    // async getReferrals(userAddress, contractVersion, contractId) {
    //     if (!this[contractId]) {
    //         await this.init();
    //     }
    //     const result = await this[contractId].getReferralsNumber(userAddress);
    //     return Number.parseInt(result._hex);
    // }

    async getUserReward(userAddress, period = 3000) {
        /* miliseconds */
        let _this = this;

        setTimeout(async function tick() {
            try {
                const rewardPageData = await _this.getInfoForRewardPage(userAddress);

                _this.context.$store.commit("setRewardPageData", rewardPageData);
                setTimeout(tick, period);
            } catch (error) {
                console.log(error);
                // setTimeout(tick, 300);
            }
        }, 300);
    }

    async getStakeAmount(userAddress, stakeId, contractVersion, decimals) {
        let resultRaw = await this[contractVersion].getStakeAmount(userAddress, stakeId);

        let result = Number(ethers.utils.formatUnits(resultRaw, decimals));
        return result;
    }

    async getRewardForPeriod(stakedAmount, stakedToken, rewardToken, contractVersion) {
        try {
            let decimals = this.Config.tokens.find((el = el.address.toLowerCase() === stakedToken.toLowerCase())).decimals;
            const farmTokenDecimals = this.Config.tokens
                .find((el) => el.address.toLowerCase() === stakedToken.toLowerCase())
                .farmTokensList.find((farmEl) => farmEl.address.toLowerCase() === rewardToken.toLowerCase()).decimals;

            let { V1TotalStake, V2TotalStake } = await this.getTotalStaking(stakedToken, decimals);

            V1TotalStake = V1TotalStake === 0 ? stakedAmount : V1TotalStake;
            V2TotalStake = V2TotalStake === 0 ? stakedAmount : V2TotalStake;
            // V3TotalStake = V3TotalStake === 0 ? stakedAmount : V3TotalStake

            if (contractVersion === "V3") {
                const totalStakingBig = ethers.utils.parseUnits(`${V3TotalStake}`, decimals);
                const stakedAmountBig = ethers.utils.parseUnits(`${stakedAmount}`, decimals);

                const rewardRaw = await this[contractVersion].getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, totalStakingBig);
                const reward = ethers.utils.formatUnits(rewardRaw, farmTokenDecimals);

                return reward;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async getPossibleDailyReward(stakedAmount, stakedToken, rewardToken) {
        try {
            let decimals = this.Config.tokens.find((el = el.address.toLowerCase() === stakedToken.toLowerCase())).decimals;
            const farmTokenDecimals = this.Config.tokens
                .find((el) => el.address.toLowerCase() === stakedToken.toLowerCase())
                .farmTokensList.find((farmEl) => farmEl.address.toLowerCase() === rewardToken.toLowerCase()).decimals;
            // let {V1TotalStake, V2TotalStake} = await this.getTotalStaking(stakedToken)
            let { V1TotalStake, V2TotalStake } = await this.getTotalStaking(stakedToken, decimals);

            V1TotalStake = V1TotalStake === 0 ? stakedAmount : V1TotalStake;
            V2TotalStake = V2TotalStake === 0 ? stakedAmount : V2TotalStake;

            const stakedAmountBig = ethers.utils.parseUnits(`${stakedAmount}`, decimals);

            const V1newTotalStaking = Number(stakedAmount) + V1TotalStake;
            const V1newTotalStakingBig = ethers.utils.parseUnits(`${V1newTotalStaking}`, decimals);

            const V2newTotalStaking = Number(stakedAmount) + V2TotalStake;
            const V2newTotalStakingBig = ethers.utils.parseUnits(`${V2newTotalStaking}`, decimals);

            const V3newTotalStaking = Number(stakedAmount) + V3TotalStake;
            const V3newTotalStakingBig = ethers.utils.parseUnits(`${V3newTotalStaking}`, decimals);

            const V1RewardRaw = await this.V1.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, V1newTotalStakingBig);
            const V1Reward = ethers.utils.formatUnits(V1RewardRaw, farmTokenDecimals);

            const V2RewardRaw = await this.V2.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, V2newTotalStakingBig);
            const V2Reward = ethers.utils.formatUnits(V2RewardRaw);

            const V3RewardRaw = await this.V3.getRewardForPeriod(stakedAmountBig, stakedToken, rewardToken, V3newTotalStakingBig);
            const V3Reward = ethers.utils.formatUnits(V3RewardRaw);

            return {
                V1Reward,
                V2Reward,
                V3Reward,
            };
        } catch (error) {
            console.log(error);
        }
    }

    withoutRound(number, roundTo = 2) {
        if (!number || !isFinite(number)) {
            return roundTo === 2 ? "0.00" : roundTo === 4 ? "0.0000" : "0.00000000";
        }

        if (roundTo === 2) {
            if (number.toString().indexOf(".") !== -1) {
                const splittedNumber = number.toString().split(".");
                splittedNumber[1] += "00";
                number = splittedNumber.join(".");

                return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            } else {
                number = number.toString() + ".00";
                return number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            }
        } else if (roundTo === 4) {
            if (number.toString().indexOf(".") !== -1) {
                const splittedNumber = number.toString().split(".");
                splittedNumber[1] += "00";
                number = splittedNumber.join(".");

                return number.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
            } else {
                number = number.toString() + ".00";
                return number.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
            }
        } else if (roundTo === 8) {
            if (number.toString().indexOf(".") !== -1) {
                const splittedNumber = number.toString().split(".");
                splittedNumber[1] += "00";
                number = splittedNumber.join(".");

                return number.toString().match(/^-?\d+(?:\.\d{0,8})?/)[0];
            } else {
                number = number.toString() + ".00";
                return number.toString().match(/^-?\d+(?:\.\d{0,8})?/)[0];
            }
        }
    }
}
