<template>
    <div id="app">
        <choose-wallet-modal
            :noWallet="noWallet"
            :showWalletOptions="showWalletOptions"
            @close="(showWalletOptions = false), (noWallet = false), (showProceedToMetamaskModal = false)"
            @setWalletAndAddress="setWalletOption(getWalletOption())"
            :showProceedToMetamaskModal="showProceedToMetamaskModal"
        />
        <install-wallet-modal
            :noWallet="noWallet"
            @setWalletAndAddress="setWalletOption(getWalletOption())"
            @close="(noWallet = false), (showWalletOptions = false)"
        />
        <onto-wallet v-if="showOntoTutorial" />
        <router-view class="app__inner" @changeWalletRequest="showWalletOptions = true" @setWalletAndAddress="setWalletOption(getWalletOption())" />
        <footer>
            <div class="footer">
                <hr />
                <div class="responsive">
                    <div class="footer-up">
                        <a v-if="$route.name === 'Main'" class="main_logo" href="#">
                            <div class="logo">
                                <span class="logo__span"></span>
                            </div>
                        </a>
                        <router-link v-else class="main_logo" tag="a" :to="{ name: 'Main' }">
                            <div class="logo">
                                <span class="logo__span"> </span>
                            </div>
                        </router-link>
                        <div class="bring__pages">
                            <div class="bring__pages-nav">
                                <div>
                                    <router-link class="hover" :to="{ name: 'pool' }" active-class="active"> {{ lang.get("POOL") }} </router-link>
                                </div>
                                <div>
                                    <router-link class="hover" :to="{ name: 'stake' }" active-class="active"> {{ lang.get("YOUR_STAKE") }} </router-link>
                                </div>
                                <div>
                                    <router-link class="hover" :to="{ name: 'referral' }" active-class="active"> {{ lang.get("REFERRAL") }} </router-link>
                                </div>
                            </div>
                            <div class="bring__pages-user">
                                <div>
                                    <a href="https://bring.gitbook.io/bring/" target="_blank">lightpaper</a>
                                </div>
                                <!--                              <div>-->
                                <!--                                <a href="https://bring.finance/blog ">Blog</a>-->
                                <!--                              </div>-->
                            </div>
                        </div>
                        <div class="link-soc">
                            <p class="follow-soc">follow us</p>
                            <div class="links-main">
                                <div class="container-link" data-cy="list-links">
                                    <div>
                                        <a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"><span class="twitter"></span></a>
                                    </div>
                                    <div>
                                        <a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"><span class="medium"></span></a>
                                    </div>
                                    <div>
                                        <a href="https://www.youtube.com/channel/UCyG5ZG13kiXvvSeRxdIG_jg" rel="nofollow" target="_blank"
                                            ><span class="youtube"></span
                                        ></a>
                                    </div>
                                    <div>
                                        <a href="https://t.me/bRing_community" rel="nofollow" target="_blank"
                                            ><span class="telegram speaker"
                                                ><div class="telegram-group">
                                                    <i class="i-team-line"></i>
                                                </div> </span
                                        ></a>
                                    </div>
                                    <div>
                                        <a href="https://t.me/bRing_channel" rel="nofollow" target="_blank"
                                            ><span class="telegram speaker"
                                                ><div class="telegram-group">
                                                    <i class="i-volume-up-line"></i>
                                                </div> </span
                                        ></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="link-soc-mobile">
                                <div class="links-main">
                                    <div class="container-link" data-cy="list-links">
                                        <div>
                                            <a href="https://twitter.com/BringFinance" target="_blank" rel="nofollow"><span class="twitter"></span></a>
                                        </div>
                                        <div>
                                            <a href="https://medium.com/@BringFinance" target="_blank" rel="nofollow"><span class="medium"></span></a>
                                        </div>
                                        <div>
                                            <a href="https://www.youtube.com/channel/UCyG5ZG13kiXvvSeRxdIG_jg" rel="nofollow" target="_blank"
                                                ><span class="youtube"></span
                                            ></a>
                                        </div>
                                        <div class="tg-group" >
                                            <a href="https://t.me/bRing_community" target="_blank" rel="nofollow"><span class="telegram"></span></a>
                                            <div class="telegram-group">
                                                <i class="i-team-line"></i>
                                            </div>
                                        </div>
                                        <div class="tg-group">
                                            <a href="https://t.me/bRing_channel" rel="nofollow" target="_blank"
                                                ><span class="telegram speaker"> <i class="i-speaker"></i></span
                                            ></a>
                                            <div class="telegram-group">
                                                <i class="i-volume-up-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="responsive">
                    <div class="footer-down">
                        <span>Copyright © 2021. All rights reserved.</span>
                        <div class="policy">
                            <div>
                                <router-link :to="{ name: 'TermOfUse' }">Term of use</router-link>
                            </div>
                            <div>
                                <router-link :to="{ name: 'PrivacyPolicy' }">Privacy policy</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    import MultiLang from "@/core/multilang";
    import { mapState } from "vuex";
    import ConnectWalletModal from "../src/components/modalWindows/ConnectWalletModal";
    import Core from "../src/core/core.js";
    import ReferralController from "./controllers/ReferralController";
    import InstallWalletModal from "./components/modalWindows/InstallWalletModal.vue";
    import ChooseWalletModal from "./components/modalWindows/ChooseWalletModal.vue";
    import conf from "../Config.json";
    import OntoWallet from "@/components/modalWindows/OntoWallet";

    export default {
        components: {
            ConnectWalletModal,
            InstallWalletModal,
            ChooseWalletModal,
            OntoWallet,
        },
        data: function () {
            return {
                selectedWallet: null,
                showWalletOptions: false,
                noWallet: false,
                currentUserAddress: "0x0000000000000000000000000000000000000000",
                walletUnlocked: null,
                lang: new MultiLang(this),
                showMobileMenu: false,
                showHowToGetWallet: false,
                showAllPoolStatsModal: false,
                showProceedToMetamaskModal: false,
            };
        },
        methods: {
            handleAccountsChanged(accounts) {
                try {
                    let _this = this;
                    let currentAccount = localStorage.getItem("address");
                    if (accounts.length === 0) {
                        _this.walletUnlocked = false;
                        localStorage.removeItem("address");
                        _this.$store.commit("setCurrentAddress", "0x0000000000000000000000000000000000000000");
                        _this.currentUserAddress = "0x0000000000000000000000000000000000000000";
                        window.location.reload();

                        // MetaMask is locked or the user has not connected any accounts
                        // alert('Please connect to MetaMask.');
                    } else if (accounts[0] !== currentAccount) {
                        currentAccount = accounts[0];
                        localStorage.setItem("address", currentAccount);
                        window.localStorage.setItem("selectedNetwork", Number(window.ethereum.chainId));
                        _this.currentUserAddress = currentAccount;

                        // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                        location.reload();
                    } else if (accounts.length > 0) {
                        _this.walletUnlocked = true;
                    }
                } catch (error) {
                    console.log(error);
                }
            },

            setWalletOption(wallet) {
                let _this = this;

                if (wallet) {
                    localStorage.setItem("selectedWallet", wallet);
                    this.selectedWallet = wallet;
                }

                if (window.ethereum && this.selectedWallet === "metamask") {
                    window.ethereum
                        .request({ method: "eth_requestAccounts" })
                        .then(this.handleAccountsChanged)
                        .catch((err) => {
                            if (err.code === -32002) {
                                if (window.localStorage.getItem("address")) {
                                    window.localStorage.removeItem("address");
                                    window.location.reload();
                                }
                                _this.showProceedToMetamaskModal = true;
                            }
                            console.error(err);
                            if (err.message === "User rejected the request.") {
                                window.localStorage.removeItem("selectedWallet");
                                window.localStorage.removeItem("address");
                                return;
                            }
                        });

                    // Note that this event is emitted on page load.
                    // If the array of accounts is non-empty, you're already
                    // connected.
                    window.localStorage.removeItem("walletconnect");
                    window.ethereum.on("accountsChanged", this.handleAccountsChanged);

                    // For now, 'eth_accounts' will continue to always return an array
                } else if (this.selectedWallet === "walletconnect") {
                    if (!this.$root.core) {
                    } else {
                        this.$root.core.init();
                    }
                } else if (this.selectedWallet === "metamask" && !window.ethereum) {
                    this.noWallet = true;
                    this.showWalletOptions = false;
                } else if (this.selectedWallet === "onto" && window.onto) {
                    window.onto
                        .request({ method: "eth_requestAccounts" })
                        .then(this.handleAccountsChanged)
                        .catch((err) => {
                            if (err.code === -32002) {
                                _this.showProceedToMetamaskModal = true;
                                window.localStorage.removeItem("address");
                            }
                            if (err.message === "The user rejected the request.") {
                                window.localStorage.removeItem("selectedWallet");
                                window.localStorage.removeItem("address");
                                return;
                            }
                        });

                    // Note that this event is emitted on page load.
                    // If the array of accounts is non-empty, you're already
                    // connected.
                    window.localStorage.removeItem("walletconnect");
                    window.onto.on("accountsChanged", this.handleAccountsChanged);

                    // For now, 'eth_accounts' will continue to always return an array
                } else if (this.selectedWallet === "onto" && !window.onto) {
                    this.noWallet = true;
                    this.showWalletOptions = false;
                }
            },

            handleAccountsChanged(accounts) {
                let currentAccount = localStorage.getItem("address");
                let _this = this;

                if (accounts.length === 0) {
                    _this.walletUnlocked = false;
                    localStorage.removeItem("address");
                    _this.$store.commit("setCurrentAddress", "0x0000000000000000000000000000000000000000");
                    _this.currentUserAddress = "0x0000000000000000000000000000000000000000";
                    window.location.reload();

                    // MetaMask is locked or the user has not connected any accounts
                    // alert('Please connect to MetaMask.');
                } else if (accounts[0] !== currentAccount) {
                    currentAccount = accounts[0];
                    localStorage.setItem("address", currentAccount);
                    window.localStorage.setItem("selectedNetwork", Number(window.ethereum.chainId));
                    _this.currentUserAddress = currentAccount;

                    // _this.$root.core.setLangForAddress(localStorage.getItem("lang"), localStorage.getItem('address'));
                    location.reload();
                } else if (accounts.length > 0) {
                    _this.walletUnlocked = true;
                }
            },

            getWalletOption() {
                const selWallet = localStorage.getItem("selectedWallet");
                if (!selWallet) {
                    this.showWalletOptions = true;
                    this.selectedWallet = null;
                    return null;
                } else {
                    this.selectedWallet = selWallet;
                    return selWallet;
                }
            },

            handleChainChanged(_chainId) {
                // We recommend reloading the page, unless you must do otherwise

                window.location.reload();
            },
        },
        computed: {
            ...mapState(["currentAddress", "tokensTotalStake", "showOntoTutorial"]),

            addressShort() {
                if (this.currentAddress) {
                    return this.currentAddress.slice(0, 3) + "..." + this.currentAddress.slice(this.currentAddress.length - 3, this.currentAddress.length);
                }
            },
        },
        async mounted() {
            this.lang.init();
            const clearLocalStorage = window.localStorage.getItem("deployBlock");
            if (!clearLocalStorage || clearLocalStorage != conf.PRIMARY_BLOCKCHAIN.CONTRACT_DEPLOYMENT_BLOCK) {
                window.localStorage.clear();
                window.localStorage.setItem("deployBlock", conf.PRIMARY_BLOCKCHAIN.CONTRACT_DEPLOYMENT_BLOCK);
                window.location.reload();
            }
            let _this = this;
            if (window.ethereum && window.localStorage.getItem("selectedWallet") === "metamask") {
                window.ethereum.on("chainChanged", (_chainId) => {
                    const network = Number(_chainId);
                    window.localStorage.setItem("selectedNetwork", network);
                    window.localStorage.removeItem(`${network}_stakeInfoTime`);
                    // window.localStorage.removeItem(`${network}_stakesAddr:${_this.currentUserAddress}`);

                    window.location.reload();
                });
                // window.ethereum.on("disconnect", () => {
                //     window.localStorage.removeItem("address");
                //     console.log("should reload");
                //     window.location.reload();
                // });
                window.ethereum.on("accountsChanged", this.handleAccountsChanged);
            } else if (window.onto && window.localStorage.getItem("selectedWallet") === "onto") {
                window.onto.on("chainChanged", (_chainId) => {
                    const network = Number(_chainId);
                    window.localStorage.setItem("selectedNetwork", network);
                    window.localStorage.removeItem(`${network}_stakeInfoTime`);
                    // window.localStorage.removeItem(`${network}_stakesAddr:${_this.currentUserAddress}`);

                    window.location.reload();
                });
                // window.ethereum.on("disconnect", () => {
                //     window.localStorage.removeItem("address");
                //     console.log("should reload");
                //     window.location.reload();
                // });
                window.onto.on("accountsChanged", this.handleAccountsChanged);
            } else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                //might be issue when wc is not connected
            }

            window.addEventListener("message", async function (e) {
                if (e.data && e.data.data && e.data.data.data && e.data.data.data.method === "metamask_unlockStateChanged") {
                    this.walletUnlocked = e.data.data.data.params.isUnlocked;
                }
            });

            // let connected = await window.ethereum.isConnected();
            // const chainId = await window.ethereum.request({ method: 'eth_chainId' });
            // if(connected && chainId !== "0x61") {

            // }

            let tronConnectAttemptsCounter = 0;

            ReferralController.setReferrerAddressIfExists(this);

            const walletOption = _this.getWalletOption();

            setTimeout(function checkIfTotalStakeAvailable() {
                try {
                    if (_this.tokensTotalStake.N1) {
                        _this.showAllPoolStatsModal = true;
                    } else {
                        if (_this.$root.core.N1) {
                            _this.$root.core.getSiteData();
                        }
                        setTimeout(checkIfTotalStakeAvailable, 1000);
                        return;
                    }
                } catch (error) {
                    console.log(error);
                    if (window.localStorage.getItem("selectedWallet") === "metamask" && window?.ethereum) {
                        setTimeout(checkIfTotalStakeAvailable, 1000);
                        return;
                    } else if (window.localStorage.getItem("selectedWallet") === "onto" && window?.onto) {
                        setTimeout(checkIfTotalStakeAvailable, 1000);
                        return;
                    }
                }
            }, 1200);
            setTimeout(async function initContract() {
                try {
                    // _this.$root.core = new Core(_this);

                    if (walletOption) {
                        if (walletOption === "metamask" && !window.ethereum) {
                            throw Error("No Metamask extension found");
                        }
                        if (walletOption === "onto" && !window.onto) {
                            throw Error("No Onto extension found");
                        }

                        if (window.ethereum && window.localStorage.getItem("selectedWallet") === "metamask") {
                            await timer(200);

                            _this.$root.core = new Core(_this);
                            await timer(100);

                            const isSupported = conf.SUPPORTED_BLOCKCHAINS.find((networkId) => networkId === Number(window.ethereum.chainId));

                            if (!isSupported) {
                                _this.$root.core.getSiteData();

                                let currentAccount = localStorage.getItem("address") || "0x0000000000000000000000000000000000000000";
                                _this.$store.commit("setCurrentAddress", currentAccount);
                                _this.currentUserAddress = currentAccount;
                                _this.$root.core.fetchUserStakesFromAllNetworks(currentAccount);
                                return;
                            }
                            //won't commit address without these checks

                            const previousChain = window.localStorage.getItem("selectedNetwork");
                            if (previousChain && Number(previousChain) !== Number(window.ethereum.chainId)) {
                                console.log("chain id missmatch");
                                window.localStorage.setItem("selectedNetwork", Number(window.ethereum.chainId));
                                window.location.reload();
                            }

                            let currentAccount = localStorage.getItem("address") || "0x0000000000000000000000000000000000000000";
                            _this.$store.commit("setCurrentAddress", currentAccount);
                            _this.currentUserAddress = currentAccount;

                            _this.setWalletOption(_this.getWalletOption());

                            // _this.$root.core.updateSiteStats(50000);
                            // _this.$root.core.getCurrentRate();

                            // if(currentAccount){
                            //   _this.$root.core.updateUserStatsAndTokensBalance(5000, currentAccount);

                            // }
                            _this.$root.core = new Core(_this);

                            // let result = await _this.$root.core.N1;
                            // debugger;
                            // if (result == undefined) {
                            //     throw Error();
                            // }
                            if (_this.currentUserAddress) {
                                _this.$root.core.getSiteData();
                                _this.$root.core.getUserReward(currentAccount);
                                _this.$root.core.updateUserInfo(currentAccount);
                                _this.$root.core.getCurrentRate();
                            } else {
                                console.log("here?");
                            }
                        } else if (window.localStorage.getItem("selectedWallet") === "walletconnect") {
                            const WC_Obj = JSON.parse(window.localStorage.getItem("walletconnect"));
                            _this.$root.core = new Core(_this);

                            const isSupported = conf.SUPPORTED_BLOCKCHAINS.find((networkId) => networkId === Number(WC_Obj?.chainId));

                            if (!isSupported) {
                                _this.$root.core.getSiteData();

                                let currentAccount = localStorage.getItem("address") || "0x0000000000000000000000000000000000000000";
                                _this.$store.commit("setCurrentAddress", currentAccount);
                                _this.currentUserAddress = currentAccount;
                                _this.$root.core.fetchUserStakesFromAllNetworks(currentAccount);
                                return;
                            }

                            const previousChain = window.localStorage.getItem("selectedNetwork");
                            if (previousChain && Number(previousChain) !== Number(WC_Obj.chainId)) {
                                console.log("chain id missmatch");
                                window.localStorage.setItem("selectedNetwork", Number(WC_Obj.chainId));
                                window.location.reload();
                            }
                            let currentAccount = localStorage.getItem("address") || "0x0000000000000000000000000000000000000000";
                            _this.$store.commit("setCurrentAddress", currentAccount);
                            _this.currentUserAddress = currentAccount;

                            _this.setWalletOption(_this.getWalletOption());

                            if (_this.currentUserAddress) {
                                _this.$root.core.getSiteData();
                                _this.$root.core.getUserReward(currentAccount);
                                _this.$root.core.updateUserInfo(currentAccount);
                                _this.$root.core.getCurrentRate();
                            } else {
                            }
                        } else if (window.localStorage.getItem("selectedWallet") === "onto" && window.onto) {
                            _this.$root.core = new Core(_this);
                            _this.$root.core.getSiteData();
                            await window.onto.enable();

                            await timer(100);
                            const isSupported = conf.SUPPORTED_BLOCKCHAINS.find((networkId) => networkId === Number(window.onto.chainId));

                            if (!isSupported) {
                                _this.$root.core.getSiteData();

                                let currentAccount = localStorage.getItem("address") || "0x0000000000000000000000000000000000000000";
                                _this.$store.commit("setCurrentAddress", currentAccount);
                                _this.currentUserAddress = currentAccount;
                                _this.$root.core.fetchUserStakesFromAllNetworks(currentAccount);
                                return;
                            }
                            //won't commit address without these checks

                            const previousChain = window.localStorage.getItem("selectedNetwork");
                            if (previousChain && Number(previousChain) !== Number(window.onto.chainId)) {
                                console.log("chain id missmatch");
                                window.localStorage.setItem("selectedNetwork", Number(window.onto.chainId));
                                window.location.reload();
                            }

                            let currentAccount = localStorage.getItem("address") || "0x0000000000000000000000000000000000000000";
                            _this.$store.commit("setCurrentAddress", currentAccount);
                            _this.currentUserAddress = currentAccount;

                            _this.setWalletOption(_this.getWalletOption());

                            // _this.$root.core.updateSiteStats(50000);
                            // _this.$root.core.getCurrentRate();

                            // if(currentAccount){
                            //   _this.$root.core.updateUserStatsAndTokensBalance(5000, currentAccount);

                            // }
                            _this.$root.core = new Core(_this);
                            // let result = await _this.$root.core.N1;
                            // debugger;
                            // if (result == undefined) {
                            //     throw Error();
                            // }
                            if (_this.currentUserAddress) {
                                _this.$root.core.getSiteData();
                                _this.$root.core.getUserReward(currentAccount);
                                _this.$root.core.updateUserInfo(currentAccount);
                                _this.$root.core.getCurrentRate();
                            } else {
                            }
                        }
                        // else {
                        //     console.log("missing metamask?");
                        //     _this.noWallet = true;

                        //     _this.$root.core.getSiteData();
                        //     // _this.$root.core = new Core(_this, blockchain);
                        //     // _this.$root.core.updateSiteStats(50000);
                        //     // _this.$root.core.getCurrentRate();
                        // }
                    } else {
                        console.log("wallet option is invalid: ", walletOption);
                        _this.showWalletOptions = true;
                        console.log("invoked wallet option invalid");
                        _this.$root.core = new Core(_this);
                        _this.$root.core.getSiteData();
                        // _this.$root.core.updateSiteStats(50000);
                        // _this.$root.core.getCurrentRate();
                    }
                } catch (ex) {
                    console.log(ex);
                    // if(ex.message === "Request of type 'wallet_requestPermissions' already pending for origin"){
                    //     window.localStorage.removeItem('address')
                    // }
                    if (ex.message === "Already processing eth_requestAccounts. Please wait.") {
                        _this.$root.core = new Core(_this);
                        return;
                    }
                    tronConnectAttemptsCounter++;
                    if (tronConnectAttemptsCounter > 5 && (ex.message == "No Metamask extension found" || ex.message === "No Onto extension found")) {
                        _this.noWallet = true;
                        console.log("error core creationg");
                        _this.$root.core = new Core(_this);
                        _this.$root.core.getSiteData();
                        // _this.$root.core.updateSiteStats(50000);
                        return;
                    }

                    if (window.localStorage.getItem("selectedWallet") === "onto" && ex.code === 4001) {
                        window.localStorage.removeItem("selectedWallet");
                        window.localStorage.removeItem("address");
                        return;
                    }
                    if (window.localStorage.getItem("selectedWallet") === "metamask" && ex.code === 4001) {
                        window.localStorage.removeItem("selectedWallet");
                        window.localStorage.removeItem("address");
                        return;
                    }

                    if (tronConnectAttemptsCounter > 10) {
                        if (window.ethereum == undefined) {
                            console.log("error core creation 2");
                            _this.$root.core = new Core(_this);
                            // _this.$root.core.updateSiteStats(50000);
                        } else {
                            if (_this.selectedWallet === "metamask") {
                                if (!window.ethereum.chainId) {
                                    //for some reasons in mobile metamask chainId may be null
                                    window.location.reload();
                                    return;
                                }
                            }
                        }
                    } else {
                        // if(tronConnectAttemptsCounter > 3 && _this.selectedWallet === "metamask" && window.ethereum && !window.ethereum.chainId){
                        //   setTimeout(initContract, 500);
                        // }
                        console.log("re init");
                        setTimeout(initContract, 500);
                    }
                }
            }, 1000);
        },

        watch: {
            walletUnlocked: function () {
                let _this = this;
                setTimeout(function check() {
                    if (_this.walletUnlocked === false) {
                        // _this.checkWalletOption(_this.selectedWallet)
                        _this.currentUserAddress = "0x0000000000000000000000000000000000000000";

                        setTimeout(check, 1000);
                    } else if (_this.walletUnlocked === true) {
                    }
                }, 0);
            },
            showWalletOptions: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            showMobileMenu: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },

            showProceedToMetamaskModal: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
            noWallet: function (next) {
                const body = document.body;
                if (next === true) {
                    body.style.overflow = "hidden";
                } else {
                    body.style.overflow = "auto";
                }
            },
        },
    };
</script>
