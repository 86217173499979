import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermOfUse from "../components/TermOfUse";
import NotFound from "../components/NotFound.vue";
import ServerError from "../components/ServerError.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Main",
        component: Home,
        beforeEnter(to, from, next) {
            window.scrollTo({
                top: 0,
                behavior: "instant",
            });
            next();
            // ...
        },
    },
    {
        path: "/privacy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        beforeEnter(to, from, next) {
            window.scrollTo({
                top: 0,
                behavior: "instant",
            });
            next();
            // ...
        },
    },
    {
        path: "/termOf",
        name: "TermOfUse",
        component: TermOfUse,
        beforeEnter(to, from, next) {
            window.scrollTo({
                top: 0,
                behavior: "instant",
            });
            next();
            // ...
        },
    },
    {
        path: "/cabinet",
        component: () => import("@/views/Cabinet.vue"),
        children: [
            {
                path: "",
                name: "cabinet",
                component: () => import("@/views/cabinet/Index.vue"),
                children: [
                    {
                        path: "pool",
                        name: "pool",
                        component: () => import("@/views/cabinet/tabs/Pool"),
                        beforeEnter(to, from, next) {
                            window.scrollTo({
                                top: 0,
                                behavior: "instant",
                            });
                            next();
                            // ...
                        },
                    },

                    {
                        path: "stake",
                        name: "stake",
                        component: () => import("@/views/cabinet/tabs/Stake"),
                        beforeEnter(to, from, next) {
                            window.scrollTo({
                                top: 0,
                                behavior: "instant",
                            });
                            next();
                            // ...
                        },
                    },
                    {
                        path: "referral",
                        name: "referral",
                        component: () => import("@/views/cabinet/tabs/Referral"),
                        beforeEnter(to, from, next) {
                            window.scrollTo({
                                top: 0,
                                behavior: "instant",
                            });
                            next();
                            // ...
                        },
                    },
                ],
            },
        ],
    },
    {
        path: "/server-error",
        component: ServerError,
        name: "ServerError",
    },
    {
        path: "/:notFound(.*)",
        component: NotFound,
        name: "NotFound",
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
