<template>
  <div>
    <header-component/>
    <div class="container">
      <div class="responsive">
        <div class="privacy-policy">
          <h1>PRIVACY POLICY</h1>
          <p class="disc-privacy-policy">
            This Privacy Notice describes the policies and procedures of bRing Finance, ("Company", “we,” “our,” or “us”)
            pertaining to the collection, use, and disclosure of your information on
            https://bring.finance/ and related mobile applications and products we offer (the “Service” or “bRing”).
          </p>
          <div>
            <p class="name-privacy-policy">1. Foreword</p>
            <div>
              <p class="disc-privacy-policy">
                1.1. Company enables users (hereinafter “Users”) registered on the website bRing.finance
                (hereinafter the “Website”) to benefit from an enhanced user interface.
              </p>
              <p class="disc-privacy-policy">
                1.2. Company, develops, operates and provides an online application that enables
                You to benefit from a Decentralized Staking platform, offering the best conditions
                on the market regarding token token staking on Ethereum's based blockchain (hereinafter the “Service”).
              </p>
              <p class="disc-privacy-policy">
                1.3. For more information regarding the Service, You are invited to read the Terms of Use available on the Website
              </p>
              <p class="disc-privacy-policy">
                1.4. Company firmly believes that trust is key to its relationships with You.
                In this respect, the protection of your personal data and privacy is our top priority.
              </p>
              <p class="disc-privacy-policy">
                1.5. This is why Company puts great emphasis on collecting
                and processing your personal data with the utmost care and in compliance with the applicable legal framework.
              </p>
              <p class="disc-privacy-policy">
                1.6. To inform You transparently, Company has implemented the privacy policy below,
                which explains in detail why and how your personal data is processed when You use our Service.
              </p>
            </div>
            <p class="name-privacy-policy">2. Scope</p>
            <div>
              <p class="disc-privacy-policy">
                2.1. This privacy policy (hereinafter the “Privacy Policy”) is intended to inform You, as a visitor or a User of the Service
                (hereinafter “You”), through any terminal, application or device (hereinafter the “Equipment”), about the way the Company processes, as controller,
                information enabling to identify You either directly or indirectly (hereinafter “Personal Data”) in the context of your use of the Service.
              </p>
              <p class="disc-privacy-policy">
                2.2. The Privacy Policy is accessible at any time on the Website and prevails over any previous version.
              </p>
              <p class="disc-privacy-policy">
                2.3. The Privacy Policy may evolve. The up-to-date version is the one available on the Website.
              </p>
            </div>
            <p class="name-privacy-policy">3. Data collected and Purposes</p>
            <div>
              <p class="disc-privacy-policy">
                3.1. When You use the Website, Company processes Personal Data for special purposes, each of them being duly legitimated by a valid legal basis.
              </p>
              <p class="disc-privacy-policy">
                3.2. The legal basis on which Company relies for this purpose is the performance of a contract to which You are a party.
              </p>
              <p class="disc-privacy-policy">
                3.3. Second, Company is willing to understand how Users interact with its Service and need to process various browsing information resulting
                from Cookies (see Article 8) which qualify as Personal Data, for the performance of analytic operation related to the Service’ use.
              </p>
              <p class="disc-privacy-policy">
                3.4. In this respect, the legal basis Company relies upon its legitimate interest which consists of
                (i) understanding the way its Website is browsed by Users and visitors (including You); and (ii) improving the Website where needed.
              </p>
              <p class="disc-privacy-policy">
                3.5. Third, Company collects and processes your Personal Data for managing and following up any questions and/or requests
                You may submit. As such, please note that this processing is only carried out in the event You submit a question and/or request.
                Otherwise, your Personal Data is not processed for this purpose.
              </p>
              <p class="disc-privacy-policy">
                3.6. This processing requires Company to collect and process the following categories of Personal Data: (i)
                identification data (i.e. your username and your contact details) and (ii) the content of the message(s)
                You sent to Company as part of your question/request
              </p>
              <p class="disc-privacy-policy">
                3.7. While processing your Personal Data for this purpose, Company relies on
                its legitimate interest, which consists in duly managing its relationships with You.
              </p>
              <p class="disc-privacy-policy">
                3.8. You are under no obligation to provide the requested Personal Data. Nevertheless,
                Company draws to Your attention that, in such case, access to the Service may be altered, if not impossible.
              </p>
              <p class="disc-privacy-policy">
                3.9. To use the Service, You shall connect your own cryptocurrency
                wallet to the Service. However, your wallet stays under your sole control. Consequently, You acknowledge
                that the Service cannot collect any information from it. Moreover, You may, at any time, log off your Wallet from the Service
              </p>
              <p class="disc-privacy-policy">
                3.10. In any event, and disregarding the processing purpose at stake, please note that Company will comply with
                a strict data minimization principle and will thus only collect and process Personal Data which is necessary for the above purposes.
              </p>
            </div>
            <p class="name-privacy-policy">4. Data recipients</p>
            <div>
              <p class="disc-privacy-policy">
                4.1. Company shares your Personal Data with third-party service providers
                and suppliers which assist Company for fulfilling the purposes specified in this Privacy Policy.
                The service providers and suppliers may have access to Personal Data for the sole and exclusive purpose of
                carrying out the missions assigned to them. Company ensures that the service providers and suppliers
                have sufficient guarantees for the performance of the mission and comply with the applicable laws and regulations.
                bRing Finance – Privacy Policy 4/6
              </p>
              <p class="disc-privacy-policy">
                4.2. Furthermore, as the case may be, Company shares your Personal Data with competent courts and
                any other governmental and/or public authorities requesting
                access to your Personal Data, within the extent legally permitted.
              <p class="disc-privacy-policy">
                4.3. In any event, Company communicates your Personal
                Data to the above recipients on a strictly need-to-know basis
                and only as necessary for fulfilling duly identified processing purposes
              </p>
            </div>
            <p class="name-privacy-policy">5. Storage period</p>
            <div>
              <p class="disc-privacy-policy">
                5.1. Company stores Personal Data for a limited duration,
                not exceeding the fulfillment of purposes described in Article 3 of this Privacy Policy.
              </p>
              <p class="disc-privacy-policy">
                5.2. First, regarding the transfer or exchange of cryptocurrencies, the Company will retain your Personal Data until
                the log-off of your wallet from the Service.
                Nevertheless, if Company needs to retain your Personal Data for evidence purposes beyond the date
                on which your wallet is logged off, the maximum retention period applicable will then be in line with
                the statute of limitation and will not exceed the period during which Company is required to retain evidence.
              </p>
              <p class="disc-privacy-policy">
                5.3. Second, the cookies' retention period is indicated below, under article 8.
              </p>
              <p class="disc-privacy-policy">
                5.4. Third, with respect to the management of your questions and/or requests,
                Company will not retain your Personal Data for more than five (5) years following the last contact on your initiative.
              </p>
              <p class="disc-privacy-policy">
                5.5. Where Company considers it does not need to retain your Personal Data in its active database, it will archive it and will
                ensure that access thereto is restricted to a limited number of persons who have an actual need to access your Personal Data.
              </p>
            </div>
            <p class="name-privacy-policy">6. Rights of the User</p>
            <div>
              <div>
                <p class="disc-privacy-policy">
                  6.1. As a data subject, You benefit from various rights regarding the processing of your Personal Data. These are as follows:
                </p>
                <ul>
                  <li class="point-menu">
                    - right to request from Company access to and rectification or erasure of your Personal Data;
                  </li>
                  <li class="point-menu">
                    - right to request from Company restriction of the processing concerning You; bRing Finance – Privacy Policy 5/6
                  </li>
                  <li class="point-menu">
                    - right to object to the processing of your Personal Data;
                  </li>
                  <li class="point-menu">
                    - right to portability of your Personal Data;
                  </li>
                  <li class="point-menu">
                    - right to specify guidelines regarding the use of your Personal Data after your death
                  </li>
                </ul>
              </div>
              <p class="disc-privacy-policy">
                6.2. To exercise your rights or for any question on Personal Data protection,
                You shall make a request accompanied by proof of identity by email addressed to bRing Finance at contact@bRing.finance.
              <p class="disc-privacy-policy">
                6.3. Company shall strive to reply without undue delay and at the latest within one (1) month after the receipt of
                the request. Company reserves the right to extend this period to three (3) months in the case of a complex request.
              </p>
              <p class="disc-privacy-policy">
                6.4. Company is committed to protect your Personal Data and comply with the applicable data protection legal framework.
              </p>
              <p class="disc-privacy-policy">
                6.5. You commit to inform the Company in case the Personal Data You shared with us becomes obsolete
                or inaccurate. This is the reason why Company requires your assistance to this end.
              </p>
              <p class="disc-privacy-policy">
                6.6. In addition, in the event You provide Company
                with information enabling to identify directly or indirectly any other natural persons
                (e.g. You sent a request to Company with the contact email address available on the
                Service and share personal data concerning another natural person in your email), You represent
                and warrant that, prior to sharing this information with Company, such other natural persons have
                been provided with this Privacy Policy and, to the extent applicable, have consented to the processing of their data.
              </p>
            </div>
            <p class="name-privacy-policy">7. Cookies</p>
            <div>
              <p class="disc-privacy-policy">
                7.1. You are informed that information may be transmitted to Your browser or Equipment by the Service (“Cookies”).
                When You browse the Service for the first time, a Cookie banner may be displayed requesting You to accept, refuse or configure Cookies.
              </p>
              <p class="disc-privacy-policy">
                7.2. The maximum storage period for Cookies is thirteen (13)
                months from the time they are placed on your browser or Equipment. At the end of this period, new consent will be required.
              <p class="disc-privacy-policy">
                7.3. You may accept, refuse and delete certain or all Cookies
              </p>
              <p class="disc-privacy-policy">
                7.4. You are informed that the refusal of certain Cookies may affect the delivery of the Service provided and the navigation on the Website.
              </p>
              <p class="disc-privacy-policy">
                7.5. Company informs You that Cookies may be configured in
                the help menu of your browser, at the following URLs: Google ; Mozilla Firefox ;Safari ; Edge and Opera.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../components/HeaderComponent.vue";

export default {
  components: {
    HeaderComponent,
  },
  name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>