<template>
    <div>
        <div v-if="showWalletOptions" class="mod">
            <div class="modal__inner choose-wallet">
                <div class="modal__up">
                    <p class="modal__name">{{ lang.get("WALLET_NEEDED") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down metamask-wallet">
                    <button @click="setupDesiredWallet('metamask'), $emit('setWalletAndAddress')" class="metamask">
                        <span class="wallet-metamask"></span>
                        Metamask Extension
                    </button>
                    <br />
                    <button @click="setupDesiredWallet('onto'), $emit('setWalletAndAddress')" class="metamask">
                        <span class="wallet-onto"></span>
                        Onto Extension
                    </button>
                    <br />
                    <button @click="setupDesiredWallet('walletconnect'), $emit('setWalletAndAddress')" class="binance">
                        <span class="wallet-connect"></span>
                        Wallet Connect
                    </button>
                </div>
            </div>
        </div>
        <div v-if="showProceedToMetamaskModal" class="mod">
            <div class="modal__inner choose-wallet">
                <div class="modal__up">
                    <p class="modal__name">Warning!</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down metamask-wallet">
                    <p class="login__warning">Please proceed to Metamask to login.</p>
                    <button @click="$emit('close')" class="metamask modal__button">Close</button>
                    <!-- <button class="binance modal__button"><span class="wallet-connect"></span>
					binanse wallet
				</button> -->
                </div>
            </div>
        </div>
        <!-- <div v-if="noWallet" class="mod">
            <div class="modal__inner">
                <div class="modal__up">
                    ???
                    <p class="modal__name">{{ lang.get("INSTALL_WALLET") }}</p>
                    <i @click="$emit('close')" class="i-close-line"></i>
                </div>
                <div class="modal__down">
                    <button @click="setupDesiredWallet('metamask'), $emit('setWalletAndAddress')" class="metamask modal__button">
                        <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en" target="_blank">
                            <span class="wallet-metamask"></span> Metamask</a
                        >
                    </button>
                    
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
    import MultiLang from "@/core/multilang";
    export default {
        name: "ConnectWalletModal",
        props: ["showWalletOptions", "noWallet", "showProceedToMetamaskModal"],
        data: function () {
            return {
                lang: new MultiLang(this),
            };
        },
        computed: {
            getSelecteWallet() {
                return window.localStorage.getItem("selectedWallet");
            },
        },
        methods: {
            async setupDesiredWallet(walletOption) {
                const previousSelection = window.localStorage.getItem("selectedWallet");
                if (walletOption && previousSelection && walletOption.toLowerCase() === previousSelection.toLowerCase()) {
                    this.$emit("close");
                }
                if (walletOption) {
                    if (walletOption === "metamask" && previousSelection === "walletconnect") {
                        window.localStorage.setItem("selectedWallet", walletOption);
                        await this.$root.core.killSession();
                    }
                    window.localStorage.setItem("selectedWallet", walletOption);
                }
            },
        },
        mounted() {
            this.lang.init();
        },
    };
</script>
