<template>
  
<div class="container">
    <ul class="faq-list" data-cy="list-faq">
      <li v-for="(faq, idx) in faqs" :key="idx" data-cv="list-faq"
          itemprop="mainEntity"
          itemtype="https://schema.org/Question">
        <button @click="$emit('openFaq', idx)" :class="{active: faq.isOpen}" class="">
           <h3 itemprop="name" class="question">
             {{ lang.get(`${faq.title}`, "faq") }}
           </h3>
            <i class="i-arrow-drop-down-line"></i>
           <div v-html="lang.get(`${faq.description}`, 'faq')" class="answer"
                itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"></div>
        </button>        
      </li>
    </ul>
</div>

</template>

<script>
import MultiLang from "@/core/multilang";

export default {
name: "FaqBlocks",
data: function() {
  return {
    lang: new MultiLang(this),
  }
},
props: {
    faqs: {
      type: Array
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

</style>